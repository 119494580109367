// filtr
$( document ).ajaxComplete(function() {
    filtery     = false;
    var path = decodeURI($(location).attr('href'));
    if(path.indexOf('producer') != -1 || path.indexOf('delkalyze') != -1 || path.indexOf('price') != -1){
        filtery = true;
    }
    if(filtery === true){
        $('#content.search .remove-filter').removeClass('close');
    }else{
        $('#content.search .remove-filter').addClass('close');
    }
});

$(function() {
    /* GENERAL BINDS */
    $('.fancybox').fancybox();
    $(".tabs").tabs();
    $('.anchor').anchor({ anchor:'body' });
    $('.anchor, .paginator a').livequery(function(){
        $(this).anchor({ speed: 800});
    });
    $('.btn-fb-login').html(document.myTranslate.fbLogin);

    // zavreni modalu
    $('#hidInteractiveCont .close, #hidInteractiveContShadow').live('click',function(){
        $('#hidInteractiveCont').attr('style','display: none;');
        $('#hidInteractiveContShadow').removeClass('show');
    });
    // cart btns
    $('#frm-cartDeliverySelector-payForm .back-to-cart, #frmformUser-back, #frmnoteForm-back').removeClass('btn');
    $('#frmpayForm-changePaymant, #frmformUser-save, #frmnoteForm-changePaymant-top, #frmnoteForm-changePaymant').addClass('btn-success');


    // breadcrumbs
    $('#breadcrumb .first a').html(document.myTranslate.mainPage);

    // tooltip
	/*
    $('[data-toggle="tooltip"], .q-tip').livequery(function(){
        $(this).tooltip();
    });
	*/

    // forms
    $('select').each(function(){
        $(this).wrap( "<div class='select'></div>" );
    });

    /* FLASH MESSAGES */
    if($('.flash-message').length > 0) {
        $.magnificPopup.open({
            items: {
                src: '#flashMessage'
            },
            type: 'inline',
            fixedContentPos: true,
            callbacks: {
                open: function() {},
                close: function() {}
            }
        });
    }

    /* notifikacna */

    // zavreni cookie
    $("#topMessageToggle").click(function(e){
        //set cookie
        $.cookie('top-message-cookie', '1', { expires: 3650, path: '/' });

        //close
        $("#topMessage").removeClass('show');
        e.preventDefault();
    });

    if ( typeof $.cookie('top-message-cookie') === 'undefined' ){
        $("#topMessage").addClass('show');
    }

    /* cookie lista */

    // zavreni cookie
    $("#cookie-box-close").click(function(e){
        //set cookie
    	$.cookie('cookie-box-cookie', '1', { expires: 3650, path: '/' });
        //close
    	$("#cookies-box").removeClass('show');
    	e.preventDefault();
    });
    if ( typeof $.cookie('cookie-box-cookie') === 'undefined' ){
    	setTimeout(function(){
            $("#cookies-box").addClass('show');
    	}, 1000);
    }


    /* ADD TO CART
        default vsade kde je product card
        v snippetoch check contentFunc -> search
    */
    /*$('.addToCart').on('click', function(evt) {
        addToCart($(this), evt);
    });*/

    /* orderer, paginator
        -> contentFunc.search-tabs
    */

    /* MEDZIKROK KOSIKU */
    // pozri initFunc.js -> addToCart

    /* CONTENT FUNC */
    var contentClass = $('#content').attr('class').split(' ');
    for (var i in contentClass) {
        var func = contentClass[i];

        func = camelizeClassName(func);
        // alert(camelizeClassName(func));

        if (typeof contentFunc[func] !== 'undefined') {
            contentFunc[func]();
        }

        if (typeof customContentFunc[func] !== 'undefined') {
            customContentFunc[func]();
        }
    }
});
