/* FUNKCIE K RUN.JS */

// responsive bg
function updateBgSrc(picture) {
    var vw = $(window).width();
    var bgData = (vw < 768) ? 'data-src-mobile' :
            (vw < 992) ? 'data-src-tablet' :
            (vw < 1200) ? 'data-src-notebook' : 'data-src-desktop';

    var bgUrl = $(picture).attr(bgData);
    $(picture).attr('data-bg-src', bgUrl);

    return bgUrl;
}

function updateBg(picture) {
    var bgUrl = updateBgSrc(picture);
    $(picture).css('background-image', 'url(' + bgUrl + ')');
}

/* search - priceslider*/
function limitParamRange(rangeParam, val) {
    var s = 'limiter['+rangeParam+'][min]=' + val[0] + '&limiter['+rangeParam+'][max]=' + val[1];
    var ur = decodeURIComponent(window.location) + "";
    console.log(s);
    if (ur.indexOf('?') != -1 ){
        if (ur.indexOf('limiter['+rangeParam+']') != -1 ){
            var match = '';
            var params = getURLParams();
            for(var key in params){
                if(key == 'limiter['+rangeParam+'][min]'){
                    match = ''+key+'='+params[key];
                    ur = ur.replace(match,'limiter['+rangeParam+'][min]=' + val[0]);
                }
                if(key == 'limiter['+rangeParam+'][max]'){
                    match = ''+key+'='+params[key];
                    s = ur.replace(match,'limiter['+rangeParam+'][max]=' + val[1]);
                }
            }

        } else {
            s = $(window.location).attr('href')+'&'+s;
        }
    } else {
        s = $(window.location).attr('href')+'?'+s;
    }

    
    $.nette.ajax({
        url: s
    },this).complete(function () {
        window.history.pushState('', '', s);
    });
}

function limitQueryRange(rangeParam, val, defaultRange) {
    var s = 'limiter['+rangeParam+'][min]=' + val[0] + '&limiter['+rangeParam+'][max]=' + val[1];
    var ur = decodeURIComponent(window.location) + ""; 

    if (ur.indexOf('?') != -1 ){
        if (ur.indexOf('limiter['+rangeParam+']') != -1 ){
            var match = '';
            var params = getURLParams();
            for(var key in params){
                if(key == 'limiter['+rangeParam+'][min]'){
                    match = ''+key+'='+params[key];
                    ur = ur.replace(match,'limiter['+rangeParam+'][min]=' + val[0]);
                }
                if(key == 'limiter['+rangeParam+'][max]'){
                    match = ''+key+'='+params[key];
                    s = ur.replace(match,'limiter['+rangeParam+'][max]=' + val[1]);
                }
            }

        } else {
            s = $(window.location).attr('href')+'&'+s;
        }
    } else {
        s = $(window.location).attr('href')+'?'+s;
    }



    
    $.nette.ajax({
        url: s
    },this).complete(function () {
            window.history.pushState('', '', s);      
    });
}

// cart navigation - bottom
function copyCartNav(elemBack, elemNext) {
    if ($('#cartNavigateBack').length) {
        $(elemBack).addClass('btn--reset');
        $(elemBack).appendTo('#cartNavigateBack');
        relabelCartNav(elemBack);
        $(elemBack).show();
    }

    if ($('#cartNavigateNext').length) {
        $(elemNext).addClass('btn--reset');
        $('#cartNavigateNext').empty();
        $(elemNext).appendTo('#cartNavigateNext');
        relabelCartNav(elemNext);
        $(elemNext).show();
    }
}

function relabelCartNav(elem) {
    var elemTagName = $(elem).prop('tagName');

    var text = $(elem).closest('.cart-navigate-btn').data('text');

    if (elemTagName.toUpperCase() === 'INPUT') {
        $(elem).attr('value', text);
    } else {
        $(elem).html(text);
    }
}
