/**
 * init editable option on your site
 */
var startedEditable = false;
$(document).keydown(function(event){
  if (startedEditable==true)
    return;
  if ($('*[data-editable]').length==0)
    return;
  if (event.which == 113 ) {
    startedEditable = true
    $('body').prepend('<div id="editable-status-bar">Byl zapnut systém pro editaci textů.</div>');
    $('*[data-editable]').animate({
      opacity: .1
    }, 300, function(){$('*[data-editable]').animate({
      opacity: 1
    }, 300)})
    $('*[data-editable]').addClass('alfax-editable');
    $('*[data-editable]').parents('a').removeAttr('href');
    $('*[data-editable]').editInPlace({
      bg_over: 'transparent',
      callback: function(original_element, html, original){
        $.nette.ajax({
          url:  '/admin/editable/?key='+$(this).attr('data-editable')+'&value='+html+'&do=edit'
        });
        return html;
      }
    });      
  }
})