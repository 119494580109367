function unResponsiveJS() {
    $('body').removeClass('mobile');

    // SEARCH COPY
    $('#headerSearchForm').appendTo('#headerSearch');
    $('.mfp-close').trigger('click');

    // MENU COPY
    //$('#leftMenu').prependTo('#sidebar');
    $('#headerLanguageSelect').appendTo('#headerLanguage');
    $('#headerPhoneLink').appendTo('#headerPhone');

}

function unResponsiveJS_XStoSM() {
    /* FILTRATION COPY */
    /*
    $('#filterMore').appendTo('#filterMoreDesktop');
    $('#filterMore').addClass('hidden');
    $('#filterMore .filter-more').addClass('padding');
    $('#frmparamsForm-send').off('click');

    $('#ordererTabsWrap').appendTo('#ordererTabsDesktop');
    $('#search-tabs li a').off('click');
    */
}

function detectXStoSM(wasMobileXS, vw) {
    if (wasMobileXS && !detectMobileXS(vw)) {
        return true;
    }
    return false;
}
