$('a.paginator-next-button').live('click', function(event){
	event.preventDefault();
    if ($.active){
    	return false;
	}
    // back compatibility
    $.nette.ajax({
    	url: $(this).attr('href'),  
        off: {
          snippets: true
        },
        success: function(data){ 
          var s = data['snippets']['snippet--productsList'];
          $(s).children('li').each(function(e){
              $(this).hide();
              $(this).appendTo('#products-list');
          })
          $('#products-list li:hidden').fadeIn();
          data['snippets']['snippet--productsList'] = undefined;
          //$.nette.success(data);
          	if (this.url && this.url != window.location.href && !$.browser.msie) {
                history.pushState({
                nette: true,
                href: this.url,
                title: document.title
                }, document.title, this.url);
            }
        }
      });
      return false;
});    