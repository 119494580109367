var jsClose = document.querySelector('.js-close');
var jsOverlay = document.querySelector('.js-overlay');
var jsBtn = document.querySelector('.js-btn');
var jsFormText = document.querySelector('.js-form-text');

var close = function() {
    jsOverlay.classList.add('is-hidden')
}

var hasLocalStorage = function() {
    try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return true;
    } catch(e) {
        return false;
    }
}

var setLocalStorage = function() {
    if(hasLocalStorage) {
        localStorage.setItem('hidePopup', '1');
    }
}

var isMobile = detectMobile();
var vw = window.innerWidth;
var isMobileXS = detectMobileXS(vw);

var defaultMagnific = {
    type: 'inline',
    midClick: true,
    removalDelay: 300,
    mainClass: 'mfp-fade',
    fixContentPos: true
};


$(function () { //document ready

    if (localStorage.getItem('hidePopup') === null) {
        if (jsOverlay && jsOverlay !== null) {
            jsOverlay.classList.remove('is-hidden')
        }
    }

    if (jsClose && jsBtn) {
        jsClose.addEventListener('click', function() {
            setLocalStorage();
            close();
        });

        jsBtn.addEventListener('click', function(e) {
            new ClipboardJS('.js-btn');
            jsFormText.classList.add('is-visible');
            setTimeout(function() {
                jsFormText.classList.remove('is-visible');
            }, 1500);
        })
    }

	// ZAKAZE SCROLLOVANI PRI HOVER NA MENU
	function stopWheel(e){
		if(!e){ /* IE7, IE8, Chrome, Safari */ 
			e = window.event; 
		}
		if(e.preventDefault) { /* Chrome, Safari, Firefox */ 
			e.preventDefault(); 
		} 
		e.returnValue = false; /* IE7, IE8 */
	}
	$('.menu-lvl-1').hover(function() {
		$(document).bind('mousewheel DOMMouseScroll',function(){ 
			stopWheel(); 
		});
	}, function() {
		$(document).unbind('mousewheel DOMMouseScroll');
	});
	
	function disableArrowsScroll() {
		document.addEventListener("keydown", function (e) {
			e.preventDefault(); 
		}, false);
	}
	
	function disableTouchScroll() {
		document.addEventListener('ontouchstart', function(e) {
			e.preventDefault(); 
		}, false);

		document.addEventListener('ontouchmove', function(e) {
			e.preventDefault(); 
		}, false);
	}
	

	// DARKER PAGE
	
	$(document).ready(function() {
		$('.menu-lvl-1').on('hover', function() {
			//disableArrowsScroll();
			//disableTouchScroll();
			//$('.under-menu').fadeOut(200);
			var underMenu = $(this).find('.under-menu');
			$(this).toggleClass('active');
			underMenu.toggleClass('active-submenu');
			//underMenu.find('.categories').fadeToggle(0);
			//underMenu.slideToggle(0);
			if (underMenu.hasClass('active-submenu') && $('.menu-lvl-1').hasClass('active') && $('.under-menu').is(':visible')) {
				$(".dark-page").addClass("active-dark");
			}
			
			if (!underMenu.hasClass('active-submenu')) {
				$(".dark-page").removeClass("active-dark");
			}

		});
		
		$('.menu-lvl-1').mouseleave(function() {
			var underMenu = $(this).find('.under-menu');
			if ($(this).hasClass('active')) {				
				$(".dark-page").removeClass("active-dark");
				$(this).removeClass('active');
				underMenu.removeClass('active-submenu');
			}
		});
	});
	
    /* RESPONSIVE */
    $(window).on('load resize', function() {
        isMobile = detectMobile();
        vw = window.innerWidth;

        if (isMobile) {
            // fix na android - focus lose na copy input
            if (!$('input:focus').length) {
                responsiveJS(vw);
            }
        } else {
            unResponsiveJS();
        }

        if (detectXStoSM(isMobileXS, vw)) {
            unResponsiveJS_XStoSM();
        }

        $('.responsive-bg').each(function() {
            updateBg($(this));
        });

        isMobileXS = detectMobileXS(vw);
    });

    /* LOAD ELEMENT JS */
    $('.mf-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 300,
        mainClass: 'mfp-fade',
        fixContentPos: false
    });

    // LOGIN
    $('#login-form').submit(function(e){
        console.log('login');
        e.preventDefault();
        $('.login-error').hide();

        var formData = {
            'login'     : $('input[name=login]').val(),
            'password'  : $('input[name=password]').val()
        };

        if (formData.login === '' || formData.password === '') {
            $('#login-empty-error').show();
        } else {
            $.ajax({
                url     : '/?do=login',
                data    : formData,
                type    : 'POST',
                dataType: 'JSON',

                success: function(data) {
                    if ( data.result == 'ok' ) {
                        $('#loginPopup').magnificPopup('close');
                        $('.header-user-link').toggleClass('hidden');

                        $('.header-user-name .header-hrefs').empty();
                        $('.header-user-name .header-hrefs').append(formData.login);
                    } else {
                        $('#login-pass-error').show();
                    }
                }
            });
        }
    });

    $('.header-user-name').click(function() {
        $('.header-user-menu').toggleClass('hidden');
    });

    /* LEFTMENU */
    $('#mobileMenu .leftmenu-toggle').on('click',function(){
        var topList = $(this).closest('.leftmenu-list');
        var topListLevel = $(this).attr('data-level');

        var item = $(this).closest('.leftmenu-item');
        var itemActive = $(item).hasClass('leftmenu-item--active') ? true : false;

        $(topList).find('.leftmenu-item[data-level=' + topListLevel + ']').removeClass('leftmenu-item--active');

        if (!itemActive) {
            $(item).toggleClass('leftmenu-item--active');
        }
    });

    /* ANCHORS */
    /*$('body').on('click', '.anchor', function () {
        var target = (typeof $(this).attr('href') !== 'undefined') ? $(this).attr('href') : $(this).data('href');
        //ak nema ani href ani data-href, tak bind na body
        target = $(target).length ? target : $('body');

        // set defaults
        var trigger = $(this);
        var trig_offset = parseInt(trigger.data('offset'));
        if (isNaN(trig_offset)) {
            trig_offset = 0;
        }
        var trig_speed = parseInt(trigger.data('speed'));
        if (isNaN(trig_speed)) {
            trig_speed = 300;
        }

        // scroll
        if (target.length) {
            $('html,body').animate({
                scrollTop: $(target).offset().top + trig_offset
            }, trig_speed);
        }
    });*/
});
