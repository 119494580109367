/* INDIVIDUALNY KOD PRE PODSTRANKY */

var contentFunc = {
    default: function() {

        $('#top-slide').slick({
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "autoplay": true,
            "autoplaySpeed": 6000,
            "fade": true
        });

        $('#content.default #default-slick').slick({
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1000,
            responsive: [{
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4
                }
            },{
                breakpoint: 769,
                settings: {
                    slidesToShow: 3
                }
            },{
                breakpoint: 612,
                settings: {
                    slidesToShow: 1
                }
            }]
        });

        // newsletter
        $('#content.default .home-newsletter #frmform-send').val(document.myTranslate.singUpNews);
        $('#frmform-email').attr('placeholder', document.myTranslate.yourEmail);

        if($('#content.default .flash').length || $('#content.default .flash').length){
            if($('.flash.success').length){
                setTimeout(function(){
                    swal({
                        title: document.myTranslate.thanks,
                        text: $('.flash').html(),
                        confirmButtonColor: "#249fc6",
                        type: "success",
                        confirmButtonText: document.myTranslate.continue
                        });
                }, 2000);
            }else{
                setTimeout(function(){
                    swal({
                        title: document.myTranslate.notice,
                        text: $('.flash').html(),
                        confirmButtonColor: "#249fc6",
                        type: "error",
                        confirmButtonText: document.myTranslate.continue
                    });
                }, 2000);
            }
            $('.flash.success').hide();
        }

        //zaskrtnutie GDPR checkboxu na newsletter a nasledne skrytie
        $('#frm-newsletterform-form input.gdpr-checkbox').attr('checked', true);
        $('#frm-newsletterform-form input.gdpr-checkbox').closest('tr').hide();

    },
    search: function() {
        
		document.querySelector('#ajax-spinner').style = 'display: block';
		
		
		// =============== ZAJISTUJE ZPETNE NAKLIKANOU FILTRACI V COOKIES ================		
		/*
		var urlParams = getURLParams();
		var urlParamsLength = 0;
		var limitersToCookies = "";
		var limitersCount = 0;
		var limiter;
		
		// Zjisti velikost objektu
		for (var param in urlParams) {
			urlParamsLength++;
		}
		
		// Vytvori string do cookies
		for (var param in urlParams) {
			limitersCount++;
			if (limitersCount === 1) {
				limiter = "?" + param + "=" + urlParams[param] + "&";
				limitersToCookies += limiter;
			} else if (limitersCount !== urlParamsLength) {
				limiter = param + "=" + urlParams[param] + "&";
				limitersToCookies += limiter;
			} else {
				limiter = param + "=" + urlParams[param];
				limitersToCookies += limiter;
			}
		}
		
		console.log(limitersToCookies);		
		
		
		
		$('.js-product-img, .js-product-detail').on('click', function() {
			//$.cookie("last-filtration", null, { path: '/' });
			$.cookie("last-filtration", limitersToCookies);
		});	
		
		//console.log($.cookie("last-filtration"));
		
		
		var $hrefAndLimiters;
		
		if (location.href.indexOf("limiter") > -1) {
			//$hrefAndLimiters = location.href + $.cookie("last-filtration");
			
		} else {
			//location.replace($.cookie("last-filtration"));
		}		
		*/
		// ================================================================================
		
		
		$(window).resize(function() {
			if ($(window).width() < 992) {
				//window.location.reload();
				$('#param-filters').addClass('hidden');	
				$('.param-filters--button-align').addClass('hidden');	
				/*
				if (!$('#mobileFiltrationPopUp').is(':empty')) {
					$('.mobile-open-filters').addClass('hidden');
				}
				*/
			}
		});
		
		
		// Zajistuje, ze filtrace je na mobilu v popupu
		
		function mobileFiltrationAppend() {	
			$('#interactive-filter').appendTo('#mobileFiltrationPopUp');
			$('<div class="mobile-filtration-title">' + document.myTranslate.mobileFiltrationTitle + '</div>"').insertBefore('#interactive-filter');
			$('<div class="mobile-filtration-submit-align"><div class="mobile-filtration-submit">' + document.myTranslate.filtrationSubmit + '</div></div>"').appendTo('#mobileFiltrationPopUp');

			$('.mobile-filtration-submit').on('click', function() {
				$('.mfp-close').trigger('click');
			});	
		}
		
		function desktopFiltrationAppend() {
			$('#interactive-filter').appendTo('#param-filters');
			$('.mobile-filtration-title').remove();
			$('.mobile-filtration-submit').remove();
		}
		
		// ... ON LOAD
		if ($(window).width() <= 767) {
			mobileFiltrationAppend();
		} else {
			desktopFiltrationAppend();
		}
		
		// ... ON RESIZE	
		var countResizeMobileAppend = 0;
		var countResizeDesktopAppend = 0;
		$(window).resize(function() {	
			if ($(window).width() <= 767) {		
				if (countResizeMobileAppend === 0 && $('#mobileFiltrationPopUp').is(':empty')) {
					mobileFiltrationAppend();
					countResizeMobileAppend = 1;
				}
			} else {
				if (countResizeDesktopAppend === 0) {
					desktopFiltrationAppend();
					countResizeDesktopAppend = 1;
				}
			}
			
			/*
			if (!$('.stock-availability-filter').is(':visible')) {
				if ($(window).width() > 575) {
					$('.stock-availability-filter').show();
				} else {
					$('.stock-availability-filter').hide();
				}
			}
			*/
		});
		
		
			
		
		/*
		$(window).on('scroll', function() {
			console.log($(window).scrollTop());
		});
		console.log($('#foot-com-priority').offset().top);
		*/
		// ===================================== Nová filtrace 2017
		$('.js-filtration-categories').on('click', function() {
			var $child = $('.search-filtration__subcategory');
			var $childHeight = $child.outerHeight();
			var $childCount = $child.length;
			var basic = 100;
			var basicCount = 8;
			var oneRow = 4;
			var oneRowHeight = 50;
			
			if (basicCount < $childCount) {
				var $modulus = $childCount % basicCount;				
				var $diff = $childCount / oneRow;
				var $openHeight = $diff * oneRowHeight;
				
				//console.log($openHeight);
				$('.search-categories').toggle(function() {
					console.log('klik 1');
					$(this).animate({height: $openHeight}, 300);
				}, function() {
					console.log('klik 2');
					$(this).animate({height: basic}, 300);
				});
			}
			
			//console.log($childHeight, $childCount);
		});
		
		
		// Dostupnost mobilni zobrazeni
		
		$('.mobile-stock-avail').on('click', function() {
			$('.filter-stock-avail').slideToggle(500);
			$(this).toggleClass('active');
			$(this).find('.icon--more').toggleClass('active');
		});
		
		// ========================================================
		
		$('.q-tip').on('click', function() {
			var moreVariants = $(this).parents('.js-product-card').find('.variants-more');
			moreVariants.toggleClass('active');
			var variant = moreVariants.children('p');
			var variantsCount = variant.length;
			var variantHeight = variant.height();
			var variantsContainerHeight = variantsCount * (variantHeight + 10);
			console.log(variantsCount, variantHeight + 10);
			moreVariants.height(variantsContainerHeight);			
		});

		$('.varStockName').on('click', function() {
			console.log($(this));
			$('.varStockName').removeClass('active');
			$('.varStockName').next().fadeOut(200);
			$(this).toggleClass('active');
			$(this).next().fadeToggle(200);
		});
		
		$('.variants-more-stock').on('click', function() {
			$(this).fadeOut(200);
		});

        // INFO BUBLINA
        if ($.cookie('search-bubble-cache') === 0){
            $('#content.search .info-bubble').addClass('small');
        }

        $('#content.search .info-bubble .close').live('click',function(e){
            $('#content.search .info-bubble').addClass('small');
            $.cookie('search-bubble-cache', 0, { expires: 1, path: '/'});
        });
        $('#content.search .info-bubble .show').live('click',function(e){
            $('#content.search .info-bubble').removeClass('small');
            $.cookie('search-bubble-cache', 1, { expires: 1, path: '/'});
        });

        // PAGINATOR NEXT BTN
        $('#content.search .paginator-next-button').livequery(function(){
            var count = parseInt($(this).html().replace('dalších…',''));
            var text = '';
            switch(count){
                case 1: text = document.myTranslate.nextPag+' '+count+'';break;
                case 2:
                case 3:
                case 4: text = document.myTranslate.nextPag+' '+count+'';break;
                default: text = document.myTranslate.nextXPag+' '+count+'';break;
            }
            $(this).html(text);
        });

        // LEFT MENU STICKY
		/*
        if($(window).width() >= 1720){
            $("#left-menu").sticky({ topSpacing: 20/*, bottomSpacing: 770});
        }
		*/
        $("#in-left-menu a").click(function(){
            var href = $(this).data("href");
            var path = $(location).attr('href');

            // ma limiter - je to gender, tak to resim
            if(path.indexOf("?limiter[gender]") >= 0 || path.indexOf("?limiter%5Bgender%5D") >= 0){

                // beru si limiter
                path = path.substring(path.indexOf("?"));

                // jeste ocistim od zbylych
                if(path.indexOf("&") >= 0){
                    path = path.substring(0,path.indexOf("&"));
                }
            }else{
                path = '';
            }
            window.location.href = href+path;
        });

        // FILTRACE SHOW / HIDE cokoliv
        $('#content.search .filter-btn').live('click',function(e){
            $(this).toggleClass('active');
            $('#content.search .'+$(this).attr('data-block')+'-filter-box').toggleClass('close');
        });


        //// FILTRACE

        // GENDER
        var path2 = decodeURI($(location).attr('href'));
        if(path2.indexOf('limiter[gender]=') != -1){
            lim = path2.substring(path2.indexOf('limiter[gender]=')+16,path2.indexOf('limiter[gender]=')+17);
            $('.gender .gend-'+lim).removeAttr('href').addClass('active');
        }
        $('.gender a.active').click(function(){
            $(this).removeClass('active');
            href = path2.substring (0,path2.indexOf('?')-1);
            window.location.href = href;
        });

        // ORDERER - NEJLEVNĚJŠÍ, NEJDRAŽŠÍ, NEJVĚTŠÍ SLEVA
        $('#content.search #orderer-tabs').ordererTabs([
            { name: document.myTranslate.cheapest , lim: 'product_price', val: 'asc'},
            { name: document.myTranslate.mostExpensive , lim: 'product_price', val: 'desc'},
            { name: document.myTranslate.mostsale , lim: 'product_sale_percentage' , val: 'desc'}
        ]);
        // pro mobil
        $('<select></select>').appendTo($('#ordererSelectWrap'));
        $('#search-tabs li a').each(function(){
            $('<option data-id="'+$(this).attr('id')+'">'+$(this).html()+'</option>').appendTo($('#ordererSelectWrap select'));
        });
        $('#ordererSelectWrap select').on('change',function(){
            $('#search-tabs #'+$(this).find(":selected").attr('data-id')).trigger('click');
        });

        // PRODUCER ajax
        $('#content.search #content.search #frm-producersForm.ajax input').live('change',function(e){
            $(this).parent(4).submit();
            return false;
        });
        $('#frmproducersForm-send').addClass('ajax');

        // CHECK PARAMETRY
        $('#frm-producersForm input:checkbox, #frm-paramSearchForm input:checkbox').live('change', function(){
            if($(this).is(':checked')){
                $(this).parent().addClass('checked');
            } else {
                $(this).parent().removeClass('checked');
            }
        });
        $('#content.search #frm-producersForm.ajax input').live('change',function(e){
            $(this).parent(4).submit();
            return false;
        });
        $('#frm-producersForm input').each(function(){
            if($(this).attr('type') == 'checkbox' && $(this).is(':checked')){
               $(this).parent().addClass('checked');
            }else{
                $(this).parent().removeClass('checked');
            }
        });

        // DELKA SLIDER
        $('#content.search #length-slider').livequery(function(){
            $(this).rangeSlider({
                rangeParam: 'variants',
                defMinVal: parseInt($(this).attr('data-min-length')),
                defMaxVal: parseInt($(this).attr('data-max-length')),
                currency: $(this).attr('data-currency'),
                rangeStep: 5
            });
        });

        // PRICE SLIDER
        $('#content.search #price-slider').livequery(function(){
            $(this).rangeSlider({
                rangeParam: 'price',
                defMinVal: parseInt($(this).attr('data-min-price')),
                defMaxVal: parseInt($(this).attr('data-max-price')),
                currency: $(this).attr('data-currency'),
                rangeStep: 5
            });
        });

        // POKROCILE FILTRY
        $('#content.search #frm-paramSearchForm .teren').before('<h4>'+document.myTranslate.teren+'</h4>');
        $('#content.search #frm-paramSearchForm .delkaoblouku').before('<h4>'+document.myTranslate.delkaoblouku+'</h4>');
        //$('#content.search #frm-paramSearchForm .variants').before('<h4>'+{_'Varianty'}+'</h4>');
        $('#content.search #frm-paramSearchForm .typbezky').before('<h4>'+document.myTranslate.typbezky+'</h4>');
        $('#content.search #frm-paramSearchForm .stoupani').before('<h4>'+document.myTranslate.spodporou+'</h4>');
        $('#content.search #frm-paramSearchForm .podlozkanis').before('<h4>'+document.myTranslate.podlozkaNis+'</h4>');
        $('#content.search #frm-paramSearchForm .typvazani').before('<h4>'+document.myTranslate.typvazani+'</h4>');
        // ajax click jen na checkbox
        $('form#frm-paramSearchForm.ajax input').live('change', function(e){
            $(this).parent(4).submit();
            return false;
        });

        // POKROCILOST
        $('.pokrocilost-wrap .checkbox-btn.skill-1').paramCheck({ param: 'limiter[list]=1'});
        $('.pokrocilost-wrap .checkbox-btn.skill-2').paramCheck({ param: 'limiter[list]=2'});
        $('.pokrocilost-wrap .checkbox-btn.skill-3').paramCheck({ param: 'limiter[list]=3'});
        $('.pokrocilost-wrap .checkbox-btn').live('click',function(){
            if($(this).find('input').is(':checked')){
                $(this).find('input').prop('checked', false);
            }else{
                $(this).find('input').prop('checked', true);
            }
        });
        $.ajax({
            success: function(){
                var loc = decodeURIComponent(document.location.toString());

                if(loc.indexOf('limiter[list]=1') != -1){
                    $('.pokrocilost-wrap .checkbox-btn.skill-1').addClass('checked').find('input').attr('checked', true);
                }
                if(loc.indexOf('limiter[list]=2') != -1){
                    $('.pokrocilost-wrap .checkbox-btn.skill-2').addClass('checked').find('input').attr('checked', true);
                }
                if(loc.indexOf('limiter[list]=3') != -1){
                    $('.pokrocilost-wrap .checkbox-btn.skill-3').addClass('checked').find('input').attr('checked', true);
                }
            }
        });

        if($('#lyze-nebo-bezky').hasClass('hide-variants')){
            $('#content.search #frm-paramSearchForm .variants').hide();
        }
        if($('#lyze-nebo-bezky').hasClass('show-variants')){
            $('#content.search #frm-paramSearchForm .variants').before('<h4>'+document.myTranslate.variants+'</h4>');
        }
        if($('#lyze-nebo-bezky').hasClass('hide-pokrocilost')){
            $('#content.search .pokrocilost-wrap').hide();
        }

        // INIT filter-box close
        filtery     = false;
        filterySpec = false;

        var path = decodeURI($(location).attr('href'));

        if(path.indexOf('producer') != -1 || path.indexOf('delkalyze') != -1 || path.indexOf('price') != -1){
            filtery = true;
            $('#content.search .filter-btn.main').addClass('active');
        }
        if(path.indexOf('teren') != -1){
            filterySpec = true;
        }
        if(filtery === false && filterySpec === false){
            $('#content.search .filter-filter-box').addClass('close');
            $('#content.search .remove-filter').addClass('close');
        }
        /*if(filterySpec === false){
            $('#content.search .pofifilter-filter-box').addClass('close');
        }*/

    },
    detail: function() {
	
		
		// .. UPRAVY 2017 .. PREPINANI VARIANTY + SKLADOVOST
		// ======================================== Velikostni tabulky =================================================================	
		var currenciesObject,
            $currenciesToAppend = "";
            _filter = Array.prototype.filter;
		
		// Projde postupne object z sablony a porovnava s objektem, ktery obsahuje stitky, pokud vse projde, vygeneruji se jednotlive typy
		if (typeof sizeTable != "undefined" && typeof productSizeTableLists != "undefined") {
			for (var object in sizeTable) {
				if (object == productSizeTableLists.typ) {
					var sizeTableObject = sizeTable[object];
					for (var znacky in sizeTableObject) {
						var znackaObject = sizeTableObject[znacky];
						if (productSizeTableLists.znacka === znacky) {
							for (var urceni in znackaObject) {
								if (productSizeTableLists.urceni === urceni) {
									currenciesObject = {
										0: znackaObject[urceni]									
									};								
									for (var typy in znackaObject[urceni]) {
										$currenciesToAppend += "<span class='currency-type' data-currency='" + typy + "'>" + typy + "</span>";
									}

								}
							}
						}
					}
				}
			}
		}
		
		// ... Vlozeni jednotlivych typu do DOMu
		var $currenciesContainer = $('.product-info__currency-types');
		$currenciesContainer.append($currenciesToAppend);
		
		
		// Zobrazi typy pokud je aspon jeden
		if ($('.currency-type').length > 0) { 
			$('.product-info__currencies').show();
		}
				
		// Po kliku na variantu ...
		$('.product-info__variant').on('click', function() {
            /*var REC_PRICE_EL = document.querySelector('.trough')
            var PRICE_EL = document.querySelector('.price')
            var DISCOUNT_CONTAINER = document.querySelector('.discount')
            var recommendedPrice = parseFloat(REC_PRICE_EL.dataset.price)
            var price = parseFloat(PRICE_EL.dataset.discountPrice)*/
            
            // Varianta
			var $variant = $(this);
			// ID varianty
			var $variantId = $variant.data('variant-id');
			// reset
			$('.product-info__stock-status__content').removeClass('active');
			$('.product-info__variant').removeClass('active');			
			// zaktivneni vybrane varianty
			$variant.addClass('active');
			// zaktivni dostupnost vybrane varianty
            $('.product-info__stock-status__content[data-variant-id="' + $variantId + '"]').addClass('active');
            
            /*function resetValue() {
                if (DISCOUNT_CONTAINER) {
                    DISCOUNT_CONTAINER.innerHTML = ''
                }
            }

            if (REC_PRICE_EL && PRICE_EL) {
                if (recommendedPrice > price) {
                    var value = Math.round(((recommendedPrice - price) * 100) / recommendedPrice)
                    var frag = document.createDocumentFragment()
                    var span = document.createElement('SPAN')
                    var txtNode = document.createTextNode('-' + value)
                    
                    frag.appendChild(span)
                    frag.appendChild(txtNode)

                    resetValue()
                    DISCOUNT_CONTAINER.appendChild(frag)
                }
            }*/
		});
		
		var $allProductVariants = [];
		var $productVariant = $('.product-info__variant');
		var $mainCurrencyValueKey, $otherCurrencyValueKey;
        
        // Pristup k mondo objektu
        var mondoBool = mondoObj.mondo;

		// Po kliku na typ ...
		$('.currency-type').on('click', function() {
			var $currency = $(this);
			var $currencyType = $currency.data('currency');
			var $currencyObject = currenciesObject[0][$currencyType];
			//console.log($currencyObject);
			// reset
			$('.currency-type').removeClass('active');
			// zaktivni currency
            $currency.addClass('active');
            
            console.log($currencyType);
            console.log($currencyObject);

            console.log('----------------------------');
			
			// Prepinani variant podle typu
			$.each($productVariant, function() {
				$var = $(this);			
				
				// Ziskani klice podle value -> ES6 - Gulp nebere !!!
				/*
				function getKeyByValue(object, value) {
				  return Object.keys(object).find(key => object[key] === value);
				}
				*/
				
				// Ziskani klice podle value				
				function keyByVal(object, value) {
					for(var key in object) {
						if(object[key] === value) {
							return key;
						}
					}
				}
				
				//console.log(currenciesObject[0]['eu'], $var.attr('data-currency-eu'));
				//console.log(keyByVal(currenciesObject[0]['eu'], $var.attr('data-currency-eu')));
								
				//var $lastCurrency = $var.attr('data-last-currency', $var.html()).data('last-currency');
				//console.log($var.data('currency-eu'));
		
                // Víme, že EU bude vždy a je hlavním typem nebo Mondo

                console.log(mondoBool);
                if (mondoBool !== true) {
                    $mainCurrencyValueKey = parseFloat(keyByVal(currenciesObject[0]['eu'], $var.attr('data-currency-eu')));
                } else {
                    $mainCurrencyValueKey = parseFloat(keyByVal(currenciesObject[0]['mondo'], $var.attr('data-currency-mondo')));
                }
				//console.log('--------------------  mainCurrencyValueKey  -----------------------');
				//console.log($mainCurrencyValueKey);
				//$otherCurrencyValueKey = parseFloat(getKeyByValue(currenciesObject[0][$currencyType], $var.html()));
			
                // Pokud neni EU, bere konkretni typ a klic bere podle EU, pokud je EU, hodnoty se berou z data-atributu currency-eu primo na variante
                
                console.log($currencyObject, $mainCurrencyValueKey);

				if ($currencyType !== "eu") {
					$var.html($currencyObject[$mainCurrencyValueKey]);
				} else {
					$var.html($var.data('currency-eu'));
                }
                
                if ($currencyType !== "mondo") {
					$var.html($currencyObject[$mainCurrencyValueKey]);
				} else {
					$var.html($var.data('currency-mondo'));
				}
				
				
			});		
		});
		
        // ... on load
        var productVariants = document.querySelectorAll('.product-info__variant'),
            productVariantsCount = productVariants.length,
            addToCartBtn = document.querySelector('#addToCartFancy'),
            noVariantsPopUp = document.querySelector('.product-info__novariantchosen');

        if (productVariantsCount < 2) {            
            //$('.product-info__variant:first-child').addClass('active');
            addClass(document.querySelector('.product-info__variant'), 'active');
        }
		$('.currency-type:first-child').addClass('active');
        //addClass(document.querySelector('.currency-type'), 'active');
        $('.product-info__stock-status__items .product-info__stock-status__content:first-child').addClass('active');
        //addClass(document.querySelector('.product-info__stock-status__items').querySelector('.product-info__stock-status__content'), 'active');	
        
		
		// .. UPRAVY 2017 .. END ===========================
		
        var totalPrice = 0;
        var cena;
        var countInput = document.querySelector('#frmaddCartForm-count');
        var addBtn = document.querySelector('#addToCartFancy');
        var popupErr = document.querySelector('.js-error-popup');

        if (!document.querySelector('.product-info__variant').classList.contains('active')) {
            setTimeout(function() {
                document.querySelector('#plusMinus-1').style.opacity = '0.5'
                document.querySelector('#plusMinus-1').style.pointerEvents = 'none'
            }, 1)
            
            countInput.classList.add('is-disabled')
            addBtn.classList.add('is-disabled')
            popupErr.classList.add('is-visible')

            setTimeout(function() {
                popupErr.classList.remove('is-visible')
            }, 4500)
        }

	    if ( $('body').hasClass('cze') )
			
        {
            // CENA
            var currentCurrency = $('body').data('currency');
            $('.product-info__variant').click(function() {
                var REC_PRICE_EL = document.querySelector('.trough')
                var PRICE_EL = document.querySelector('.price')
                var DISCOUNT_CONTAINER = document.querySelector('.discount')
                if(REC_PRICE_EL){
                    var REC_PRICE_VAL = parseFloat(REC_PRICE_EL.dataset.recPrice);
                }else{
                    var REC_PRICE_VAL = parseFloat(0);
                }


                var frag = document.createDocumentFragment()
                var span = document.createElement('span')
                cena = parseFloat($('.product-info__variant.active').attr('data-price-value'));
                totalPrice = parseFloat($('#frmaddCartForm-count').val()) * cena;
                var discountVal = Math.round((REC_PRICE_VAL - totalPrice) * 100 / (REC_PRICE_VAL))

                var txtNode = document.createTextNode('-' + discountVal)
                var spanNode = document.createTextNode(' %')
                
                span.appendChild(spanNode)

                var shouldCalculate = function() {
                    return (REC_PRICE_EL && PRICE_EL) && REC_PRICE_VAL > totalPrice
                }
                
                var resetValue = function() {
                if (DISCOUNT_CONTAINER) {
                    DISCOUNT_CONTAINER.innerHTML = ''
                } else {
                        console.error("Element doesn't exist in DOM Tree")
                    }
                }
                
                var appendAll = function() {
                    frag.appendChild(txtNode)
                    frag.appendChild(span)

                    return frag
                }

                if (!isNaN(cena)) {
                    $('#product-info .addToCartForm .price').html(localePrice(totalPrice));
                    
                    if (shouldCalculate()) {
                        resetValue()
                        DISCOUNT_CONTAINER.appendChild(appendAll())
                    }
                }

                setTimeout(function() {
                    document.querySelector('#plusMinus-1').removeAttribute('style')
                }, 1)
                countInput.classList.remove('is-disabled')
                addBtn.classList.remove('is-disabled')

                if (popupErr.classList.contains('is-visible')) {
                    popupErr.classList.remove('is-visible')
                }
            });

            $('.product-info__variant.active').click();
            // sipky na hover
            // a zmena poctu kusu
            $('#frmaddCartForm-count').hover(function () {
                $('.countArrs').addClass('ac');
            },function(){
                $('.countArrs').removeClass('ac');
            });
            $("#frmaddCartForm-count").plusMinus({ def: 1, count: 1});

            $('#frmaddCartForm-count').change(function(){
                cena    =  parseFloat($('.product-info__variant.active').attr('data-price-value'));
                recCena = parseFloat($('#product-info .trough').attr('data-rec-price'));

                if(parseFloat($(this).val()) <= 0){
                    $('#product-info .addToCartForm .price').html(localePrice(cena));
                    $('#product-info .addToCartForm .trough').html(localePrice(recCena));
                }else{
                    var cenaTotal = parseFloat($(this).val()) * cena;
                    var recCenaTotal = parseFloat($(this).val()) * recCena;
                    $('#product-info .addToCartForm .price').html(localePrice(cenaTotal));
                    $('#product-info .addToCartForm .trough').html(localePrice(recCenaTotal));
                }
            });
        } 
        else if ( $('body').hasClass('svk') )
        {
              // CENA
              $('.product-info__variant').click(function() {
                var REC_PRICE_EL = document.querySelector('.trough')
                var PRICE_EL = document.querySelector('.price')
                var DISCOUNT_CONTAINER = document.querySelector('.discount')
                  if(REC_PRICE_EL){
                      var REC_PRICE_VAL = parseFloat(REC_PRICE_EL.dataset.recPrice);
                  }else{
                      var REC_PRICE_VAL = parseFloat(0);
                  }
                
                var frag = document.createDocumentFragment()
                var span = document.createElement('span')
                cena = parseFloat($('.product-info__variant.active').attr('data-price-value'));
                totalPrice = parseFloat($('#frmaddCartForm-count').val()) * cena;
                var discountVal = Math.round((REC_PRICE_VAL - totalPrice) * 100 / (REC_PRICE_VAL))

                var txtNode = document.createTextNode('-' + discountVal)
                var spanNode = document.createTextNode(' %')
                
                span.appendChild(spanNode)

                var shouldCalculate = function() {
                    return (REC_PRICE_EL && PRICE_EL) && REC_PRICE_VAL > totalPrice
                }
                
                var resetValue = function() {
                if (DISCOUNT_CONTAINER) {
                    DISCOUNT_CONTAINER.innerHTML = ''
                } else {
                        console.error("Element doesn't exist in DOM Tree")
                    }
                }
                
                var appendAll = function() {
                    frag.appendChild(txtNode)
                    frag.appendChild(span)

                    return frag
                }

                if (!isNaN(cena)) {
                    $('#product-info .addToCartForm .price').html(localePrice(totalPrice));
                    
                    if (shouldCalculate()) {
                        resetValue()
                        DISCOUNT_CONTAINER.appendChild(appendAll())
                    }
                }

                setTimeout(function() {
                    document.querySelector('#plusMinus-1').removeAttribute('style')
                }, 1);
                countInput.classList.remove('is-disabled');
                addBtn.classList.remove('is-disabled');

                if (popupErr.classList.contains('is-visible')) {
                    popupErr.classList.remove('is-visible')
                }
              })

              $('.product-info__variant.active').click();
              // sipky na hover
              // a zmena poctu kusu
              $('#frmaddCartForm-count').hover(function () {
                  $('.countArrs').addClass('ac');
              },function(){
                  $('.countArrs').removeClass('ac');
              });
              $("#frmaddCartForm-count").plusMinus({ def: 1, count: 1});

              $('#frmaddCartForm-count').change(function(){
                  cena    = parseFloat($('.product-info__variant.active').attr('data-price-value'));
                  recCena = parseFloat($('#product-info .trough').attr('data-rec-price'));

                  if(parseFloat($(this).val()) <= 0){
                      $('#product-info .addToCartForm .price').html(cena.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR'}));
                      $('#product-info .addToCartForm .trough').html(recCena.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR'}));
                  }else{
                      var cenaTotal = $(this).val() * cena;
                      var recCenaTotal = $(this).val() * recCena;
                      $('#product-info .addToCartForm .price').html(cenaTotal.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR'}));
                      $('#product-info .addToCartForm .trough').html(recCenaTotal.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR'}));
                 }
              });
              // zmena varianty a tedy ceny
              $('#product-info .addToCartForm .select-variant').change(function(){
                  $value = $(this).find(':selected');

                  var cena = parseFloat($(this).find(':selected').attr('data-price-value'));
                  var cenaTotal = $('#frmaddCartForm-count').val() * cena;
                  $('#product-info .addToCartForm .price').html(cenaTotal.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR'}));

                  $(this).find('option').removeClass('selected-variant').removeAttr('selected');
                  $value.addClass('selected-variant').attr("selected","selected");
              });
        }

        // SERIZENI LYZI

        function sendProducts(addTune) {
            var products = [];

            var item = {
                id_product: $('#addToCartFancy').attr('data-id-product'),
                id_variant: $('.product-info__variant.active').attr('data-variant-id'),
                count: $('#frmaddCartForm-count').val(),
            };

            if (typeof item.id_variant !== 'undefined') {
                $('#ajax-spinner').show();
            }

            if(addTune) {
                var itemDependent = {
                    id_product: $('#tune-vars').attr('data-tune-product'),
                    id_variant: $('#tune-vars').attr('data-var-one'),
                    count: $('#frmaddCartForm-count').val(),
                    note: $('#tune-vars').data('note')
                };
                item.dependents = [itemDependent];
            }

            if($('#input-tune-7').prop('checked')) {
                var itemDependent = {
                    id_product: $('#tune-vars').attr('data-tune-product'),
                    id_variant: $('#tune-vars').attr('data-var-two'),
                    count: $('#frmaddCartForm-count').val()
                };
                if (item.dependents) {
                    item.dependents.push(itemDependent);
                } else {
                    item.dependents = [itemDependent];
                }
            }

            var giftProducts = document.querySelectorAll('.right-detail .js-giftproduct');

            if (giftProducts.length) {
                for (var g = 0; g < giftProducts.length; g++) {
                    var gift = giftProducts[g];
                    var itemDependent = {
                        id_product: gift.dataset.id,
                        id_variant: gift.dataset.variantId,
                        count: $('#frmaddCartForm-count').val(),
                        price: 0
                    };
                    if (item.dependents) {
                        item.dependents.push(itemDependent);
                    } else {
                        item.dependents = [itemDependent];
                    }
                }
            }

            products.push(item);

            if (typeof item.id_variant !== 'undefined') {
                $.ajax({
                    url: document.myTranslate.linkToCart+'addItemsToCart',
                    data: { items: products },
                    type: 'GET',
                    dataType: 'html',
                    success: function (data, textStatus) { // funkce success zpracovává data
                        $('#hidInteractiveCont').html(data);
                        $('#hidLink').trigger('click');
                        $('#invalidateCartbox').trigger('click');
                        $('#ajax-spinner').hide();
                        $('#hidInteractiveCont').attr('style','display: block;');
                        $('#hidInteractiveContShadow').addClass('show');
                    }
                });
            } else {
                addClass(noVariantsPopUp, 'active');
                setTimeout(function() {
                    removeClass(noVariantsPopUp, 'active');
                }, 3000);
            }
        }

        $('.btn-tune').click(function(){
            $.ajax({
                url: '/tune-ski/',
                dataType: 'html',
                success: function (data, textStatus) {
                    $('#hidInteractiveCont').html(data);
                    $('#ajax-spinner').hide();
                    //$('#hidInteractiveCont').attr('style','display: block;');
                    $('#hidInteractiveCont').fadeIn(400);
                    $('#hidInteractiveContShadow').addClass('show');
                }
            });
        });
        // poradit ohledne pokrocilosti
        $('#tune-box .question-mark').live('click',function(){
            $('.question-mark').fadeOut();
            $('#tune-box .hidden').removeClass('hidden');
        });
        // click na pripojeni poznamky o serizeni lyzi
        $('#input-tune-send').live('click',function() {
            var filled = true;
            for (var i = 1; i <= 5; i++) {
                if (!$('#input-tune-' + i).val().length) {
                    filled = false;
                    $('#input-tune-' + i).addClass('has-error');
                    $('#input-tune-' + i).on('focus', function() {
                        $(this).removeClass('has-error');
                    });
                }
            }
            if (filled) {
                var text = "";
                var prep = false;

                // nachystam text
                text += document.myTranslate.personHeight+" - "+$('#input-tune-1').val()+" cm \n";
                text += document.myTranslate.personWeight+" - "+$('#input-tune-2').val()+" kg \n";
                text += document.myTranslate.personAge+" - "+$('#input-tune-3').val()+" let \n";
                text += document.myTranslate.personShoeSize+" - "+$('#input-tune-4').val()+"\n";
                text += document.myTranslate.personShoeSign+" - "+$('#input-tune-5').val()+"\n";
                text += document.myTranslate.personSkill+" - "+$('#input-tune-6 option:selected').val()+"\n";

                // chci serizene
                $('#tune-ski-checked').removeClass('hidden');

                // chci preparovane
                if($('#input-tune-7').is(':checked')){
                    text += document.myTranslate.skiPrepair;
                    prep = true;
                    $('#prep-ski-checked').removeClass('hidden');
                }else{
                // nechci preparovane
                    text += document.myTranslate.skiNotPrepair;
                    $('#prep-ski-checked').addClass('hidden');
                }

                // skryju btn chci, odkryju btn nechci
                $('.btn-tune-not').removeClass('hidden');
                $('.btn-tune').addClass('hidden');

                // pridam title o infu o serizeni
                $('#tune-vars').attr('data-note', text);
                // zavru modal
                $('#hidInteractiveCont .close').trigger('click');
                sendProducts(true);
            }
        });
        // click na nechci lyze serizene
        $('.btn-tune-not .del').click(function(){
            $('.btn-tune-not').addClass('hidden');
            $('.btn-tune').removeClass('hidden');
        });
        $('#input-no-tune-send').live('click',function(){
            sendProducts(false);
        });

        // MODAL INTERAKTIVNI KOSIK

        addToCartBtn.addEventListener('click', function() {

            var globLang = $('body').data('locale');
            var productPrice = parseFloat($('#addToCartFancy').attr('data-product-price'));
            gtag("event", "add_to_cart", {
                currency: globLang == 'CZ' ? 'CZK' : 'EUR',
                value: globLang == 'CZ' ? productPrice.toFixed(0) : productPrice.toFixed(2),
                items: [
                    {
                        item_id: $('#addToCartFancy').attr('data-id-product'),
                        item_name: $('#addToCartFancy').attr('data-product-name'),
                        quantity: $('#frmaddCartForm-count').val()
                    }
                ]
            });
            if ($('#tune-vars').length) {
                $.ajax({
                    url: '/tune-ski/',
                    dataType: 'html',
                    success: function (data, textStatus) {
                        $('#hidInteractiveCont').html(data);
                        $('#ajax-spinner').hide();
                        //$('#hidInteractiveCont').attr('style','display: block;');
                        $('#hidInteractiveCont').fadeIn(400);
                        $('#hidInteractiveContShadow').addClass('show');
                    }
                });
            } else {
                sendProducts(false);
            }
        });
		
		// Nastaveni rozlozeni popupu pokud si uzivatel nevybral zadnou variantu
		var $choosePopup = $('#choosePopup');
		$choosePopup.find('.btn-default').removeClass('btn-default').addClass('btn-primary');
		$choosePopup.find('table').css('width', '100%');
		$choosePopup.find('table tr:last-child').css('text-align', 'center');
		//$('#choosePopup tr.required').wrapAll('table');
		//$('#frmcontactForm-text').parents('tr').wrap('table');
		
		// Presune akce na detailu pokud je zarizeni tablet a mobil
		
		function appendingDetailProductActions() {
			if ($(window).width() <= 767) {
				$('.detail-product-actions').appendTo('.detail-product-actions-container');
			} else {
				$('.detail-product-actions').appendTo('.left-detail');
			}
		}
		
		appendingDetailProductActions();
		
		$(window).resize(function() {
			appendingDetailProductActions();
		});
		
		// Prenesni informaci do formulare
		$('.cant-choose').on('click', function() {
			var $popup = $('#choosePopup');
			//var $activeVariant = $('.product-info__variant.active');
			var $productName = $('h1[itemprop="name"]');
			/*
			if ($activeVariant.length > 0) {
				$popup.find('input[name="variant"]').val($activeVariant.html());	
			}
			*/
			
			if ($productName.length > 0) {
				$popup.find('textarea[name="text"]').val(document.myTranslate.cantChooseText + $productName.html());
			}
		});
		
    },
    section: function() {
		// refresh page po vybrani clanku
		/*
		$('.more').on('click', function() {
			console.log("Klik probehnul");
			var pageHref = $(this).attr('href');
			console.log(pageHref);
			location.href(pageHref);
		});		*/
		
        // Pujcovna responsive table
        $('#content.section.pujcovna table').each(function(){
            $(this).wrap('<div id="out-table-hidden"><div id="in-table-hidden"></div></div>');
        });

        // CLICK NA FILTRACNI MENU
        $("#content.section .blog-left-menu .tags-lists li a").live('click',function(){
            $("#content.section .tags-lists li a").removeClass('active');
            $(this).addClass('active');
            find = $(this).data('href');
            name = $(this).html();

            if(find !== undefined){
                // kdyz jsem v detailu, musim prehodit
                if(!$('#section-wrapper #section-blog-content').hasClass('active')){
                    backToBlog();
                }
                setTimeout(function(){
                    search(find,name);
                },250);
            }
        });

        /*
         * NACTENI DETAILU CLANKU
         * vyscrolluje nahoru
         * naplni obsah clanku
         * skryje aktualni vypis vyfiltrovanych clanku
         *
         */
        $(document).ajaxSuccess(function(event, xhr, settings, data) {
            // omezi dle settings na danou url blogu
            if(settings.url.indexOf(window.location.host+"/blog/") >= 0 ){
                $('html,body').animate({scrollTop: $('#section-wrapper').offset().top-20},'slow');
                $('#section-wrapper #default').append($(data).find('#detail-blog-content'));
                $('#section-wrapper #section-blog-content').removeClass('active').hide();
            }
        });

        // CLICK NA ZPET NA BLOG
        $('#back-to-blog').live('click',function(){
            backToBlog();
        });

        /*
         * ZPET NA BLOG VYPIS Z DETAILU
         * vyscrolluje nahoru
         * vymaze obsah clanku
         * zobrazi aktualni vypis drive vyfiltrovanych clanku
         */
        function backToBlog(){
            $('html,body').animate({scrollTop: $('#section-wrapper').offset().top-20},'slow');
            $('#detail-blog-content').detach();
            $('#section-wrapper #section-blog-content').removeClass('hidden').addClass('active').show();
            // vyhodi detail z url
            history.pushState(null, null, window.location.host+'/blog/');
        }
		
		// Pri stisku tlacitka zpet v browseru reloadne stranku a zobrazi vypis článků - obchází něco, co jen pushuje history state
		if ($('#section-blog-content').hasClass('active')) {
			$(window).on('popstate', function() {
				location.reload();
			});
		}


        /*
         * FUNKCE FILTROVANI DLE STITKU
         * zmeni nadpis
         * vsechny skryje
         * projde vsechny a odkryje patricne
         */
        function search (filter,name){
            $("#content.section h1").html(name);
            $("#content.section .clanek-box").fadeOut();
            $("#content.section .clanek-box").each(function(){
                if($(this).text().search(new RegExp(filter, "i")) < 0 && $(this).data('lists').search(new RegExp(filter, "i")) < 0){

                }else{
                    $(this).fadeIn();
                }
            });
        }

        // PRICHOD DO BLOGU ZVENKU
        var path = decodeURI($(location).attr('href'));
        if(path.indexOf('stick[blog]=blog') != -1){
            search('blog','Blog');
            $("#content.section .tags-lists li.list-1 a").addClass('active');
        }
        $('.poradna-link').live('click',function(){
            if(path.indexOf('/blog/') != -1){
                $('html,body').animate({scrollTop: $('#section-wrapper').offset().top-20},'slow');
                search('blog','Blog');
                $("#content.section .tags-lists li a").removeClass('active');
                $("#content.section .tags-lists li.list-1 a").addClass('active');
            }
        });

        // PUJCOVNA
        $('#pujcovna-menu li').each(function() {
            link = $(this).find('a');
            $(link).anchor({ anchor:$(link).attr('data-goto') });
        });

    },
    cartDefault: function() {

        $('#frmcouponForm-insert').removeClass('btn-default');
        $('#frmcouponForm-code').attr("placeholder", document.myTranslate.actionCode);
        $('#basket-table .cart-price a').addClass('q-tip');
        $('#basket-table tr.dependent td.cart-name a').attr('href','');

        //odstranit darcek, ak cena nakupu nedosahuje jeho uroven
        //vznika, ak zakaznik vyberie darcek, potom odstrani nejaku polozku z kosiku a zanikne mu narok na darcek
        var price = $('.paymant-under .price').data('price');
        var gift = false;
        var giftId;
        var giftIdProd;
        var giftLevel;
        //zistim, ci je v kosiku gift, ak ano, natiahnem jeho data
        $('#basket-table tr').each(function () {
            if ($(this).data('id-gift') == 1 ) {
                gift = true;
                giftId = $(this).data('id-item');
                giftIdProd = $(this).data('id-prod');
            }
        });

        if (gift) {
            //zistim cenovu uroven darceku
            $('.gift-boxes .gift-box .img').each(function() {
                if ($(this).data('id') == giftIdProd) {
                    giftLevel = $(this).data('level');
                }
            });

            if (giftLevel > price) {
                //zmazanie darceka z kosiku
                $('.pokracovat.btn').addClass('disabled');
                $.ajax({
                    url: '/kosik/?CartTable-idCartRow='+giftId+'&do=CartTable-delete',
                    //data: { items: products },
                    type: 'GET',
                    dataType: 'html',
                    success: function (data, textStatus) { // funkce success zpracovává data
                        window.location.href = document.myTranslate.linkToCart;
                    }
                });
            }
        }

    },
    cartPaymant: function() {
        $('span.i').click(function(){
            $('.info').hide();
        });
        $('#frmpayForm-changePaymant').val(document.myTranslate.cartPayNext);

        //informacia o EET
        $('#cart-paymant tbody').append('<tr><td colspan="2" class="eet-info">' + document.myTranslate.eetInfo + '</td></tr>');
    },
    cartUser: function() {
        $('#frmformUser-save').val(document.myTranslate.cartUserNext);
        $('#login').click(function(){
            $('div.login').show();
            $('div.newuser').hide();
            $('#login').addClass('active');
            $('#newuser').removeClass('active');
        });
        $('#newuser').click(function(){
            $('div.newuser').show();
            $('div.login').hide();
            $('#newuser').addClass('active');
            $('#login').removeClass('active');
        });

        // LOGLEJ nebo NE
        if($("#car-user-classes").hasClass('user-logged')){
            $('#content.cart.user .btn-newuser-login').hide();
        }
        if($("#car-user-classes").hasClass('flash')){
            $('#content.cart.user .newuser').hide();
            $('#content.cart.user #login').addClass('active');
        }
        if($("#car-user-classes").hasClass('withoutFlash')){
            $('#content.cart.user .login').hide();
            $('#content.cart.user #newuser').addClass('active');
        }
    },
    cartComplete: function() {
        $('#frmnoteForm-changePaymant').click(function(){
            if($('span.note').html() !== '' && $('span.note').html() !== null){
                $("#frmnoteForm-note").val($('span.note').html()+'\n\n' + $("#frmnoteForm-note").val());
            }
        });
        $('#frmnoteForm-newsletter').attr('checked', true);
        $('#frmnoteForm-changePaymant-top').click(function(){
            $('#frmnoteForm-changePaymant').trigger('click');
        });

        //GDPR
        $('#frmnoteForm-gdpr_cartComplete_obchodnipodminky').next('label.gdpr-checkbox').andSelf().wrapAll('<div class="gdpr-wrap"></div>');
        $('#frmnoteForm-gdpr_cartComplete_heureka').next('label.gdpr-checkbox').andSelf().wrapAll('<div class="gdpr-wrap"></div>');
        $('.gdpr-wrap:last').after('<hr class="clear-40 gdpr-clear">');
		
		// SCITANI V KOSIKU PRODUKTY + DOPRAVA -> resen jen pouze UX, protoze stav. i do platebni brany jdou data v poradku
		// ================================================
		var total = 0;
		var $vat = $('.js-price').attr('data-vat');
		$('.js-price').each(function() {	
			total += Math.ceil(parseInt($(this).attr('data-price')));		
		});
		console.log(localePrice(total));
		
		var $totalNoVat = Math.ceil(parseInt(total - ((total / 100) * $vat)));
		
		$('#under-cart-table .price').html(localePrice(total));
		$('#under-cart-table .small-text').html('(' + localePrice($totalNoVat) + ' bez DPH)');
		// ================================================
    },
    cartSend: function() {
        /*setTimeout(function() {
            window.location.replace($('#cartSendRedirect').attr('href'));
        }, 2000);*/
    },
    cartSuccess: function() {

    },
    sectionAll: function() {

    },
    compare: function() {

        $('#snippet--compare').hide();

        var countTd = $( "#compare-table tr:nth-child(2) td" ).length;
        if ( (( isBreakpoint('sm') || isBreakpoint('md')) && countTd < 5) ||
                (( isBreakpoint('lg') && countTd < 6)) )
        {
            $('.move').attr('style','display: none');
        } else {
            $('#move-l').addClass('disable');
        }

        var compareScroll = 0,
            compareMaxScroll = $('#in-table-hidden').prop("scrollWidth") - $('#in-table-hidden').innerWidth(),
            colWidth = $('#compare-table th:last-child').width();

        $('#in-table-hidden').on('scroll', function() {
            compareMaxScroll = $('#in-table-hidden').prop("scrollWidth") - $('#in-table-hidden').innerWidth();
            compareScroll = $(this).scrollLeft();
            colWidth = Math.ceil($('#compare-table th:last-child').width()) + 1;

            // width podla poctu produktov
            colsCount =  $('#compare-table th').length - 1;
            $('#compare-table').css('width', colsCount * colWidth + 'px');

            if (compareScroll <= 0) {
                $('#move-l').addClass('disable');
            } else {
                $('#move-l').removeClass('disable');
            }

            if (compareScroll >= compareMaxScroll) {
                $('#move-r').addClass('disable');
            } else {
                $('#move-r').removeClass('disable');
            }
        });

        $('#move-l').live("click", function(){
            if(compareScroll <= compareMaxScroll){
                $('#in-table-hidden').scrollLeft(compareScroll - colWidth);
            }
        });
        $('#move-r').live("click", function(){
            if(compareScroll >= 0){
                $('#in-table-hidden').scrollLeft(compareScroll + colWidth);
            }
        });

        // width podla poctu produktov
        colsCount =  $('#compare-table th').length - 1;
        $('#compare-table').css('width', colsCount * colWidth + 'px');


        /*
        var tdToScreen = 1;
        var countTd = $( "#compare-table tr:nth-child(2) td" ).length - 5;
        if(countTd <= 1){
            $('.move').attr('style','display: none');
        }else{
            $('#move-l').addClass('disable');
        }

        $('#move-r').live("click", function(){
            if(tdToScreen < countTd){
                tdToScreen++;
                $('#compare-table td:nth-child('+tdToScreen+'), #compare-table th:nth-child('+tdToScreen+')').attr('style','display: none !important;');
            }
            if(tdToScreen == countTd){
                $('#move-r').addClass('disable');
            }
            if(tdToScreen > 1){
                $('#move-l').removeClass('disable');
            }
            //$('#compare-table td, #compare-table th').attr('style','display: none;');
            //$('#compare-table td, #compare-table th').attr('style','display: table-cell;');
        });
        $('#move-l').live("click", function(){
            if(tdToScreen > 1){
                $('#compare-table td:nth-child('+tdToScreen+'), #compare-table th:nth-child('+tdToScreen+')').attr('style','display: table-cell !important;');
                tdToScreen--;
            }
            if(tdToScreen < countTd){
                $('#move-r').removeClass('disable');
            }
            if(tdToScreen == 1){
                $('#move-l').addClass('disable');
            }
        });*/
    }
};











// DARKY

// pokud uz mam darek, skryju moznost pridat dalsi
var countGifts = 0;
$('#basket-table tr').each(function(){
    if($(this).attr('data-id-gift') == 1){
        countGifts++;

        $(this).find('select').prop('disabled', 'disabled');
        $(this).find('.cart-price').html($(this).find('.cart-price').html().replace(document.myTranslate.zkc, document.myTranslate.forFree));
    }
});

if(countGifts !== 0){
    //$('#before-gifts').addClass('hidden-xs');
    //$('.in-before-gifts-right .btn').addClass('hidden');
    $('.acti-cart .visible-xs').remove();
    $('.in-before-gifts-right .btn-red').removeClass('btn-red').addClass('btn-primary');
}else{
    altText = $('.pokracovat').attr('data-alt-text');
    $('.pokracovat').html($('.pokracovat').html()+'<strong class="strong">'+altText+'</strong>');
}

// otevreni darku
$('#before-gifts .btn').live('click',function(){
    /*
    // tlacitko skryji
    $(this).addClass('hidden');
    // zobrazim napovedu
    $('#before-gifts h5').removeClass('hidden');
    // otevru darky
    $('#cart-gifts').removeClass('hidden');
    */

    $(this).toggleClass('active');
    $('#cart-gifts').toggleClass('hidden');
});

// zavreni darku
$('#cart-gifts .close').live('click',function(){
    // kdyz nemam vybran zobrazim btn na otevreni
    if($('#before-gifts .chosen-gift').hasClass('hidden')){
        $('#before-gifts .btn').removeClass('hidden');
    }
    // napovedu skryji
    $('#before-gifts h5').addClass('hidden');
    // zavru
    $('#cart-gifts').addClass('hidden');
});

// vyber darku
$('.gift-box.active .img').live('click',function(){
    // oznacim activni
    $('.gift-box .img').removeClass('active');
    $(this).addClass('active');
    // napovedu skryji
    //$('#before-gifts h5').addClass('hidden');

    // naplnim vybrany
    $('.chosen-gift').removeClass('hidden');
    $('.chosen-gift .img img').attr('src',$(this).attr('data-img'));
    $('.chosen-gift .name').html($(this).attr('title')+document.myTranslate.forYou);
    $('.chosen-gift .variant').html($(this).parent().find('select').clone());
    $('.chosen-gift .variant select').removeClass('hidden').wrap( "<div class='select'></div>" );

    // pokud je univerzalni
    $('.chosen-gift .variant').show();
    if($('.chosen-gift .variant option:selected').text().indexOf(document.myTranslate.universal) >= 0 ){
        $('.chosen-gift .variant').hide();
    }
    $('.chosen-gift span.btn').attr('data-id', $(this).closest('.img').attr('data-id'));
    $('.chosen-gift span.btn').attr('data-var', $(this).closest('.wrap').find('select option:selected').attr('value'));
    $('.chosen-gift span.btn').attr('data-level', $(this).closest('.wrap').find('.img').data('level'));
    // naplnim note
    $('#gift-note').val(document.myTranslate.addGift+$(this).attr('title')+document.myTranslate.zkc);
});

// vyber darku mobile
$('.gift-wrap-mobile .add-gift-btn').live('click',function(){

    var products = [];
    var product = {};
    product.id_product  =    $(this).closest('.gift-wrap-mobile').find('option:selected').attr('data-gift-id');
    product.id_variant  =    $(this).closest('.gift-wrap-mobile').find('option:selected').attr('data-gift-var');
    product.count       =    1;

    //nizsia cena darku pre slovensko
    if ($('body').hasClass('svk')) {
        product.price       =    0.01;
    }
    else {
        product.price       =    0.1;
    }
    product.gift        =    true;
    product.note        =    document.myTranslate.gift;
    products.push(product);

    $('#ajax-spinner').show();

    $.ajax({
        url: document.myTranslate.linkToCart+'addItemsToCart',
        data: { items: products },
        type: 'GET',
        dataType: 'html',
        success: function (data, textStatus) { // funkce success zpracovává data
            window.location.href = document.myTranslate.linkToCart;
        }
    });
});

// nechci darek
$('#before-gifts .chosen-gift .del').live('click',function(){
    // pokud je vyber otevreny tak zobrazim btn, jinak napovedu
    if($('#cart-gifts').hasClass('hidden')){
        $('#before-gifts .btn').removeClass('hidden');
    }else{
        $('#before-gifts h5').removeClass('hidden');
    }
    // vybrany darek skryji
    $('#before-gifts .chosen-gift').addClass('hidden');
    // vybrany darek vyprazdnim
    $('#before-gifts .chosen-gift .img img').attr('src','');
    $('#before-gifts .chosen-gift .name').html('');
    // deaktivuji vybranou polozku
    $('#cart-gifts .gift-box .img').removeClass('active');
    // naplnim note
    $('#gift-note').val('');
});

$('.chosen-gift select').live('change', function() {
    $('.chosen-gift span.btn').attr('data-var',this.value);
});
$('.chosen-gift span.btn').live('click',function(){
    var products = [];
    var product = {};
    product.id_product  =    $(this).attr('data-id');
    product.id_variant  =    $(this).attr('data-var');
    product.count       =    1;

    //nizsia cena darku pre slovensko
    if ($('body').hasClass('svk')) {
        product.price       =    0.01;
    }
    else {
        product.price       =    0.1;
    }

    product.gift        =    true;
    product.note        =    document.myTranslate.gift;
    products.push(product);

    $('#ajax-spinner').show();

    $.ajax({
        url: document.myTranslate.linkToCart+'addItemsToCart',
        data: { items: products },
        type: 'GET',
        dataType: 'html',
        success: function (data, textStatus) { // funkce success zpracovává data
            window.location.href = document.myTranslate.linkToCart;
        }
    });
 });





