var $numbersDelka = []; // Delka
var $numbersRadius = []; // Radius
var $numbersSirkaLyze = []; // Sirka lyze
var $numbersSirkaBoty = []; // Sirka boty
var $numbersVyskaPostavy = []; // Vyska postavy
var $numbersTeplotniUrceniVosku = []; // Vyska postavy
var $numbersVelikost = []; // Velikost
var $numbersObjem = []; // Objem
var $numbersVodniSloupec = []; // Vodni sloupec
var $numbersPropustnost = []; // Propustnost
var $numbersDrop = []; // Drop
var $numbersHmotnost = []; // Hmotnost

function createListsFilter(filterType, variantFilterName, containerAttrs, title, args, position){
	return extendBasicFilter(filterType, {
		'variantFilterName': variantFilterName,
		'title': title,
		'args': args,
		'position': position,

		createContainer: function(){
			return $('<div ' + containerAttrs + '></div>');
		},

		createTitle: function(title){
			return $('<div class="param-filter__title"></div>');
		},
		createElementsWrapper: function(interactiveFilter){
			return $('<div class="param-filter__content"></div>');
		},
		createElement: function(id, title, value, index, arg, data){
	
			var realValue = index;

			if(realValue !== parseInt(realValue)){
				realValue = value;				
			}
			return $('<div class="acti-checkbox fiftypercentcenter filter-list' + (value == 1 ? ' checked active ' : '') + '" data-list-id="' + id + '"><input class="checkbox-list-element" id="list:' + id + '" type="checkbox" ' + (value == 1 ? ' checked ' : '') + '/><label class="acti-checkbox-title" for="list:' + id + '">' + title + '</label></div>');
		},
		getValue: function(element){
			return (element.hasClass('active') ? 1 : 0);
		},
		initSubmit: function(element, interactiveFilter){
			$(element).click(function(){
				if($(this).hasClass('active')){
					$(this).removeClass('active');
					$(this).removeClass('checked');
					$(this).find('input').prop('checked', false);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}
				else {
					$(this).addClass('active');
					$(this).addClass('checked');
					$(this).find('input').prop('checked', true);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}

				$(element).find('input').change(function(){
				  interactiveFilter.submit(element);
				});
				
				filtrationChanges();
			});
		}
	
	});
}


function createCheckParamFilter(variantFilterName, containerAttrs, title, args, position){
	return extendBasicFilter('param', {
		'variantFilterName': variantFilterName,
		'title': title,
		'args': args,
		'position': position,

		createContainer: function(){
			return $('<div class="check-param ' + variantFilterName + '" ' + containerAttrs + '></div>');
		},

		createTitle: function(title){
			return $('<div class="param-filter__title"><div class="param-filter__info"><div class="param-filter__info__text"></div><i class="icon icon-info"></i></div>' + title + '</div>');
		},
		createElementsWrapper: function(interactiveFilter){
			return $('<div class="param-filter__content"></div>');
		},
		createElement: function(id, title, value, index, arg, data){

			var realValue = index;

			if(realValue !== parseInt(realValue)){
				realValue = value;
			}
			
			var $color, $paramColor, $colorCheck;
			if (arg !== null) {
				if (arg.indexOf('#') > -1) {
					$colorCheck = "color-checkbox";
					if (arg === '') {
						$color = "#ffffff";
					} else {
						$color = arg;
					}

					if (arg.indexOf(';') > -1) {
						var $colors = arg.split(';');	
						$paramColor = "background: linear-gradient(to right, " + $colors[0] + ", " + $colors[1] + ")";				
					} else {
						$paramColor = "background: " + $color + "";
					}
				}
			}
		
			return $('<div style="' + $paramColor + '" class="acti-checkbox' + (value == 1 ? ' checked ' : '') + '"><input class="checkbox-list-element" id="' + id + '" type="checkbox"' + (value == 1 ? ' checked="checked" ' : '') + '/><label for="' + id + '"><span class="acti-checkbox-title">' + title + ' </span><span class="count">(' + data.count + ')</span></label></div>');			
			
		},
		getValue: function(element){
			return element.find('input').is(':checked') ? 1 : 0;
		},
		setCount: function(element, count){
            if(count === 0 || count === "?" || count === ""){
                element.addClass('zero');
            }else{
                element.removeClass('zero');
            }
            element.find('span.count').html('('+count+')');
        },
		initSubmit: function(element, interactiveFilter){
			if(element.attr('data-filter-id').indexOf('filterdelka') > -1 && !element.hasClass('zero')){	
				var $numberDelka = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersDelka.push($numberDelka);	
			}	
			
			if(element.attr('data-filter-id').indexOf('filterradius') > -1 && !element.hasClass('zero')){	
				var $numberRadius = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersRadius.push($numberRadius);	
			}
			
			if(element.attr('data-filter-id').indexOf('filtersirkalyze') > -1 && !element.hasClass('zero')){	
				var $numberSirkaLyze = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersSirkaLyze.push($numberSirkaLyze);	
			}
			
			if(element.attr('data-filter-id').indexOf('filtersirkaboty') > -1 && !element.hasClass('zero')){	
				var $numberSirkaBoty = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersSirkaBoty.push($numberSirkaBoty);	
			}
			
			if(element.attr('data-filter-id').indexOf('filtervyskapostavy') > -1 && !element.hasClass('zero')){	
				var $numberVyskaPostavy = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersVyskaPostavy.push($numberVyskaPostavy);	
			}
			
			if(element.attr('data-filter-id').indexOf('filterteplotniurcenivosku') > -1 && !element.hasClass('zero')){	
				var $numberTeplotniUrceniVosku = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersTeplotniUrceniVosku.push($numberTeplotniUrceniVosku);	
			}
			
			if(element.attr('data-filter-id').indexOf('filtervelikost') > -1 && !element.hasClass('zero')){	
				var $numberVelikost = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersVelikost.push($numberVelikost);	
			}
			
			if(element.attr('data-filter-id').indexOf('filterobjem') > -1 && !element.hasClass('zero')){	
				var $numberObjem = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersObjem.push($numberObjem);	
			}
			
			if(element.attr('data-filter-id').indexOf('filtervodnisloupec') > -1 && !element.hasClass('zero')){	
				var $numberVodniSloupec = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersVodniSloupec.push($numberVodniSloupec);	
			}
			
			if(element.attr('data-filter-id').indexOf('filterpropustnost') > -1 && !element.hasClass('zero')){	
				var $numberPropustnost = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersPropustnost.push($numberPropustnost);	
			}
			
			if(element.attr('data-filter-id').indexOf('filterdrop') > -1 && !element.hasClass('zero')){	
				var $numberDrop = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersDrop.push($numberDrop);	
			}
			
			if(element.attr('data-filter-id').indexOf('filterhmotnost') > -1 && !element.hasClass('zero')){	
				var $numberHmotnost = parseFloat(element.find('.acti-checkbox-title').html().replace(',', '.'));				
				$numbersHmotnost.push($numberHmotnost);	
			}
			
			
			
			$(element).click(function(){
				if($(this).hasClass('checked')){
					$(this).removeClass('checked');
					$(this).find('input').prop('checked', false);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}
				else {
					$(this).addClass('checked');
					$(this).find('input').prop('checked', true);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();

				}

				$(element).find('input').change(function(){
				  interactiveFilter.submit(element);
				});
				
				if (!$(this).hasClass('check-delka')) {
					filtrationChanges();
				}
			});
		}
		
	

	});
}	


function createCheckProducersFilter(variantFilterName, containerAttrs, title, args, position){
	return extendBasicFilter('producers', {
		'variantFilterName': variantFilterName,
		'title': title,
		'args': args,
		'position': position,

		createContainer: function(){
			return $('<div class="check-param" ' + containerAttrs + '></div>');
		},

		createTitle: function(title){
			return $('<div class="param-filter__title producers__title">' + title + '</div>');
		},
		createElementsWrapper: function(interactiveFilter){
			return $('<div class="param-filter__content"></div>');
		},
		createElement: function(id, title, value, index, arg, data){

			var realValue = index;

			if(realValue !== parseInt(realValue)){
				realValue = value;
			}
			
			
			return $('<div class="acti-checkbox' + (value == 1 ? ' checked ' : '') + '"><input class="checkbox-list-element" id="' + id + '" type="checkbox"' + (value == 1 ? ' checked="checked" ' : '') + '/><label for="' + id + '"><span class="acti-checkbox-title">' + title + ' </span><span class="count">(' + data.count + ')</span></label></div>');			
			
		},
		getValue: function(element){
			return element.find('input').is(':checked') ? 1 : 0;
		},
		setCount: function(element, count){
            if(count === 0 || count === "?" || count === ""){
                element.addClass('zero');
            }else{
                element.removeClass('zero');
            }
            element.find('span.count').html('('+count+')');
        },
		initSubmit: function(element, interactiveFilter){
	
			
			$(element).click(function(){
				if($(this).hasClass('checked')){
					$(this).removeClass('checked');
					$(this).find('input').prop('checked', false);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}
				else {
					$(this).addClass('checked');
					$(this).find('input').prop('checked', true);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}

				$(element).find('input').change(function(){
				  interactiveFilter.submit(element);
				});
				
				if (!$(this).hasClass('check-delka')) {
					filtrationChanges();
				}
			});
		}
		
	

	});
}	

function createCheckVariantFilter(variantFilterName, containerAttrs, title, args, position){
	return extendBasicFilter('variant', {
		'variantFilterName': variantFilterName,
		'title': title,
		'args': args,
		'position': position,

		createContainer: function(){
			return $('<div class="check-param" ' + containerAttrs + '></div>');
		},

		createTitle: function(title){
			return $('<div class="param-filter__title">' + title + '</div>');
		},
		createElementsWrapper: function(interactiveFilter){
			return $('<div class="param-filter__content"></div>');
		},
		createElement: function(id, title, value, index, arg, data){

			var realValue = index;

			if(realValue !== parseInt(realValue)){
				realValue = value;
			}
				
			return $('<div class="acti-checkbox' + (value == 1 ? ' checked ' : '') + '"><input class="checkbox-list-element" id="' + id + '" type="checkbox"' + (value == 1 ? ' checked="checked" ' : '') + '/><label for="' + id + '"><span class="acti-checkbox-title">' + title + ' </span><span class="count">(' + data.count + ')</span></label></div>');			
			
		},
		getValue: function(element){
			return element.find('input').is(':checked') ? 1 : 0;
		},
		setCount: function(element, count){
            if(count === 0 || count === "?" || count === ""){
                element.addClass('zero');
            }else{
                element.removeClass('zero');
            }
            element.find('span.count').html('('+count+')');
        },
		initSubmit: function(element, interactiveFilter){
			
			$(element).click(function(){
				if($(this).hasClass('checked')){
					$(this).removeClass('checked');
					$(this).find('input').prop('checked', false);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}
				else {
					$(this).addClass('checked');
					$(this).find('input').prop('checked', true);
					interactiveFilter.submit(element);
					//filtersHeightAfterCount();
				}

				$(element).find('input').change(function(){
				  interactiveFilter.submit(element);
				});
				
				if (!$(this).hasClass('check-delka')) {
					filtrationChanges();
				}
			});
		}
		
	

	});
}	

function createStockFilter(variantFilterName, containerAttrs, title, args, position){
	return extendBasicFilter('stockAvailability', {
		'variantFilterName': variantFilterName,
		'title': title,
		'args': args,
		'position': position,

		createContainer: function(){
			return $('<div class="stock-availability-filter filter-stock-avail" ' + containerAttrs + '></div>');
		},

		createTitle: function(title){
			return $('<div class="param-filter__title">' + title + ':</div>');
		},
		createElementsWrapper: function(interactiveFilter){
			return $('<div class="param-filter__content"></div>');
		},
		createElement: function(id, title, value, index, arg, data){
			var titles = {
				0: document.myTranslate.stockAvail,
				3: document.myTranslate.stockToThreeDays,
				7: document.myTranslate.stockToSevenDays
			};

			title = titles[id];

			var realValue = index;

			if(realValue !== parseInt(realValue)){
				realValue = value;
			}	
			
			return $('<div class="acti-checkbox' + (value == 1 ? ' checked active ' : '') + '"><input class="checkbox-list-element" id="stock:' + id + '" type="checkbox" ' + (value == 1 ? ' checked ' : '') + '/><label for="stock:' + id + '"><span class="acti-checkbox-title">' + title + '</span></label></div>');			
			
		},
		getValue: function(element){
			return element.find('input').is(':checked') ? 1 : 0;
		},
		/*
		setCount: function(element, count){
			console.log(count);
			if(typeof count == 'undefined' || count == '?' || count == 0){
				element.addClass('hidden');
			}
			else {
				element.removeClass('hidden');
			}
		},
		*/
		initSubmit: function(element, interactiveFilter){
		$(element).click(function(){
				if($(this).hasClass('active')){
					$(this).removeClass('active');
					$(this).removeClass('checked');
					$(this).find('input').prop('checked', false);
					interactiveFilter.submit(element);
					
					/*
					if (!$('.stock-availability-filter .acti-checkbox[data-filter-id="0"]').hasClass('checked')) {
						$('.stock-availability-filter .acti-checkbox[data-filter-id="0"]').trigger('click');
					}
					*/
				
					filtrationChanges();
					//filtersHeightAfterCount();
				}
				else {
					$(this).addClass('active');
					$(this).addClass('checked');
					$(this).find('input').prop('checked', true);
					interactiveFilter.submit(element);
					filtrationChanges();
					//filtersHeightAfterCount();
				}

				
				
			});
		}

	});
}


function createPriceFilter(containerAttrs, position){

	return extendBasicFilter('price', {

		filterName: 'price',
        variantFilterName: 'price',
        args: {
            currency: undefined,
            step: 100
        },
        title: document.myTranslate.priceH4,
        position: position,

        createContainer: function(){
            return $('<div ' + containerAttrs + '></div>');
        },

        createTitle: function(title){
            return $('<div class="param-filter__title price-filter-title">' + document.myTranslate.priceslider + '</div>');
        },

        createElementsWrapper: function(){
            return $('<div class="price-filter-wrap elements-wrapper filter-items"></div>');
        },

        createElement: function(id, title, value, index, arg){
        	var borderValue = value;

        	var urlParams = InteractiveFilter.getURLParams();
        	var key = 'limiter[price][' + id + ']';

        	if(typeof urlParams[key] != 'undefined'){
        		value = urlParams[key];
        	}

        	return $('<div style="display:none;" class="price-sign" data-price-value="' + value + '" data-border-price-value="' + borderValue + '"></div>');
    	},

    	setCount: function(element, count){
			element.attr('data-price-count', count);
	    },

	    getValue: function(element){

	    	minValue = $('.price-sign[data-filter-id="min"]').attr('data-price-value');
			minBorderValue = $('.price-sign[data-filter-id="min"]').attr('data-border-price-value');

			maxValue = $('.price-sign[data-filter-id="max"]').attr('data-price-value');
			maxBorderValue = $('.price-sign[data-filter-id="max"]').attr('data-border-price-value');

			if(minValue != minBorderValue || maxValue != maxBorderValue){
				return element.attr('data-price-value');
			}

	    	return '';

		},

		initSubmit: function(element, interactiveFilter, args){
        	if(element.attr('data-filter-id') == 'max'){			
        		$priceFilter = $('[data-filter-name="price"]');
        		$priceFilterSignMin = $priceFilter.find('.price-sign[data-filter-id="min"]');
        		$priceFilterSignMax = $priceFilter.find('.price-sign[data-filter-id="max"]');

        		$priceFilter.find('.elements-wrapper').append('<div class="bootstrap-slider bootstrap-slider--price clearfix"><input class="price-slider"></div>');

        		$priceSlider = $('.price-slider');

        		var priceSliderPlugin = new Slider('.price-slider', {
        			min: parseInt($priceFilterSignMin.attr('data-border-price-value')),
        			max: parseInt($priceFilterSignMax.attr('data-border-price-value')),
        			step: args.step,
        			range: true,
        			tooltip: 'always',
        			tooltip_split: true,
        			formatter: function(value){
						return localePrice(value);
					},
        			value: [
        				parseInt($priceFilterSignMin.attr('data-price-value')), 
        				parseInt($priceFilterSignMax.attr('data-price-value'))
        			]
        		});

        		priceSliderPlugin.on('slideStop', function(value){
        			
        			$priceFilterSignMin.attr('data-price-value', value[0]);
        			$priceFilterSignMax.attr('data-price-value', value[1]);

        			interactiveFilter.submit(element);	
										
					filtrationChanges();

					
					filtersHeightAfterCountPrice();

        		});
				
				priceSliderPlugin.on('slide', function(value){
					
					var $priceFilterMin = $('.price-filter-min');
					var $priceFilterMax = $('.price-filter-max');

					$priceFilterMin.html(localePrice(value[0]));
					$priceFilterMax.html(localePrice(value[1]));

					
					filtersHeightAfterCountPrice();
				});
				

        	}
	    },
	    onActivate: function(element){
	      $('.price-sign').attr('data-active-filter', '1');
	    },
	    onDeactivate: function(element){
	      $('.price-sign').removeAttr('data-active-filter');
	    }

	});

}
		

function activeFiltersList() {
	// Zobrazi aktivni filtry
	// ========================================================================
	var $actiCheck = $('.acti-checkbox:not(".check-delka, .check-radius, .check-sirkalyze")');		
	var $activeFiltersContainer = '.active-filters__items';
	var _filter = Array.prototype.filter;
	
	document.querySelector('.active-filters__items').innerHTML = '';
	
	/*
	$.each($actiCheck, function() {		
		var $check = $(this);
		// Projde filtry a ty ktere mají style undefined, odeberou atribut style
		if ($check.attr('style') === 'undefined') {
			$check.removeAttr('style');
		}
		setTimeout(function() {
			if ($check.hasClass('checked')) {
				var $checkTitle = $check.find('.acti-checkbox-title').html();
				$('<div class="active-filters__item">' + $checkTitle + '</div>').hide().appendTo($activeFiltersContainer).fadeIn('normal');
			}
			
			// Aktivuje titulek a tlacitko pro reset filtru
			if ($('.active-filters__item').length > 0) {
				$('.active-filters__title').addClass('active');	
				$('.active-filters__cancel').addClass('active');		
			} else {
				$('.active-filters__title').removeClass('active');	
				$('.active-filters__cancel').removeClass('active');
			}
		}, 50);
		
	});
	*/

	_filter.call($actiCheck, function(_this) {
		if (_this.getAttribute('style') === 'undefined') {
			_this.removeAttribute('style');
		}

		setTimeout(function() {
			if (hasClass(_this, 'checked')) {
				var $checkTitle = _this.querySelector('.acti-checkbox-title').innerHTML;
				$('<div class="active-filters__item">' + $checkTitle + '</div>').hide().appendTo($activeFiltersContainer).fadeIn('normal');
			}
			
			// Aktivuje titulek a tlacitko pro reset filtru
			if ($('.active-filters__item').length > 0) {
				$('.active-filters__title').addClass('active');	
				$('.active-filters__cancel').addClass('active');		
			} else {
				$('.active-filters__title').removeClass('active');	
				$('.active-filters__cancel').removeClass('active');
			}
		}, 50);
		
	});

	// ========================================================================

	var baseUrl = window.location.pathname;
	var urlParams = InteractiveFilter.getURLParams();

	if(typeof urlParams['limiter[fulltext]'] != 'undefined'){
		baseUrl += '?limiter[fulltext]=' + urlParams['limiter[fulltext]'];
	}



	// Reset filtru
	$('.active-filters__cancel').attr('href', baseUrl);


	// Vycisti aktivni filtry a skryje cely blok
	$('.active-filters__cancel').on('click', function() {
		$('.active-filters__items').empty();
		$(this).addClass('hidden');
		$('.active-filters__title').addClass('hidden');
	});
	// ==============================================	
}


function hideEmptyFilters() {
	var _filter = Array.prototype.filter,
		checkParams = document.querySelectorAll('.check-param');

	// Skryje filtry pokud nemaji žádné položky, kontroluje jakmile se dokonci AJAX
	$(document).ajaxComplete(function(){
		_filter.call(checkParams, function(_this) {
			var checkboxFilterCount = _this.querySelectorAll('.acti-checkbox').length,
				checkboxFilterCountZero = _this.querySelectorAll('.zero').length;

			if (checkboxFilterCount === checkboxFilterCountZero) {
				addClass(_this, 'hidden');
			} else {
				removeClass(_this, 'hidden');
			}
		});
		/*
		$.each($('.check-param'), function() {
			var $filter = $(this);
			var $checkboxFilterCount = $filter.find('.acti-checkbox').length;
			var $checkboxFilterCountZero = $filter.find('.acti-checkbox.zero').length;

			if ($checkboxFilterCount === $checkboxFilterCountZero) {
				$filter.addClass('hidden');
			} else {
				$filter.removeClass('hidden');
			}

		});
		*/
	});
}

function addSpaces(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2;
}

// Propise vsechny zmeny do filtrů on change, onload
function filtrationChanges() {
	//activeFiltersList();
	hideEmptyFilters();
}

function filtersOpenHeight() {
	var $filtersHeight = 0,
		$paramFilters = $('#interactive-filter'),
		$paramFiltersContainer = $('#param-filters'),
		interactiveFilters = document.querySelector('#interactive-filter'),
		_filter = Array.prototype.filter;

		_filter.call(interactiveFilters.childNodes, function(_this) {
			if (!hasClass(_this, 'hidden')) {
				$filtersHeight += _this.offsetHeight;
			}
		});
	
		/*
	$.each($paramFilters.children(), function() {
		var $filter = $(this);
		if (!$filter.hasClass('hidden')) {
			$filtersHeight += $filter.outerHeight();
		}
	});
	*/
	
	//$('#param-filters').attr('data-opened-height', $filtersHeight);
	document.querySelector('#param-filters').setAttribute('data-opened-height', $filtersHeight);

	/*
	if ($paramFilters.hasClass('opened')) {
		$paramFilters.removeClass('opened');
	}	
	*/
	if (hasClass(interactiveFilters, 'opened')) {
		removeClass(interactiveFilters, 'opened');
	}

	// Onload vyska podle main filtru
	//$paramFilters.css('height', $mainFiltersHeight);
	interactiveFilters.setAttribute('style', 'height: ' + $mainFiltersHeight + 'px');
		
	var filtersPos = document.querySelector('#param-filters').offsetTop;

	$('.js-filtration-btn').on('click', function() {
		$btn = $(this);			
		$paramFilters.toggleClass('opened-filters');

		if (!$paramFilters.hasClass('opened-filters')) {
			$paramFilters.animate({height: $mainFiltersHeight}, 200);	
			setTimeout(function() {
				$('html,body').animate({scrollTop: filtersPos}, 400);
			}, 200);			
			setTimeout(function(){
				$paramFilters.css('overflow', '');
			}, 200);
			setTimeout(function() {					
				$btn.removeClass('opened');
			}, 300);		
		} else {
			$paramFilters.animate({height: $filtersHeight}, 200);					
			setTimeout(function() {
				$('html,body').animate({scrollTop: filtersPos}, 400);
			}, 200);
			setTimeout(function(){
				$paramFilters.css('overflow', '');
			}, 200);
			setTimeout(function() {					
				$btn.addClass('opened');
			}, 300);
		}
	});	
}

function filtersHeightAfterCount() {
	setTimeout(function() {
	
	var $filtersHeight = 0,
		$paramFilters = $('#interactive-filter'),
		$paramFiltersContainer = $('#param-filters'),
		interactiveFilters = document.querySelector('#interactive-filter'),
		_filter = Array.prototype.filter;

		/*
	$.each($paramFilters.children(), function() {
		var $filter = $(this);
		if (!$filter.hasClass('hidden')) {
			$filtersHeight += $filter.outerHeight();
		}
	});
	*/	

	_filter.call(interactiveFilters.childNodes, function(_this) {
		if (!hasClass(_this, 'hidden')) {
			$filtersHeight += _this.offsetHeight;
		}
	});

	//console.log($filtersHeight);

	//$paramFilters.css({height: $filtersHeight});
	interactiveFilters.setAttribute('style', 'height:' + $filtersHeight + 'px');

	}, 100);
}


function filtersHeightAfterCountPrice() {
	setTimeout(function() {

	
	var $filtersHeight = 0,
		$paramFilters = $('#interactive-filter'),
		$paramFiltersContainer = $('#param-filters'),
		interactiveFilters = document.querySelector('#interactive-filter'),
		_filter = Array.prototype.filter;

		/*
	$.each($paramFilters.children(), function() {
		var $filter = $(this);
		if (!$filter.hasClass('hidden')) {
			$filtersHeight += $filter.outerHeight();
		}
	});
	*/

	_filter.call(interactiveFilters.childNodes, function(_this) {
		if (!hasClass(_this, 'hidden')) {
			$filtersHeight += _this.offsetHeight;
		}
	});

	//console.log($filtersHeight);

	//$paramFilters.css({height: $filtersHeight});
	interactiveFilters.setAttribute('style', 'height:' + $filtersHeight + 'px');

	}, 1200);
}


// Slider - filter

function newFilterSlider(filterName, numbersArray, className, currency) {
	
	if (typeof currency == 'undefined') {
		currency = "";
	}
	
	$('.check-param[data-filter-name="filter' + filterName + '"] .acti-checkbox').addClass('check-' + filterName + '');

	var $countValueChecks = $('.check-param[data-filter-name="filter' + filterName + '"] .param-filter__content .acti-checkbox').length;
	var $countValueChecksZero = $('.check-param[data-filter-name="filter' + filterName + '"] .param-filter__content .acti-checkbox.zero').length;

	if ($countValueChecks !== $countValueChecksZero) {

		var $maxValue = numbersArray.reduce(function(a, b) {
			return Math.max(a, b);
		});

		var $minValue = numbersArray.reduce(function(a, b) {
			return Math.min(a, b);
		});


		$('<div class="' + className + '-slider-options" data-border-' + className + '-min-value="' + $minValue + '" data-border-' + className + '-max-value="' + $maxValue + '" data-' + className + '-min-value="' + $minValue + '" data-' + className + '-max-value="' + $maxValue + '"></div><div class="bootstrap-slider clearfix"><input class="' + className + '-slider" /></div>').appendTo('.check-param[data-filter-name="filter' + filterName + '"]');


		var $valueSlider = $('.' + className + '-slider');

		var $valueOptions = $('.' + className + '-slider-options');

		var valueSliderPlugin = new Slider('.' + className + '-slider', {
				min: parseFloat($valueOptions.attr('data-border-' + className + '-min-value')),
				max: parseFloat($valueOptions.attr('data-border-' + className + '-max-value')),
				step: 0.1,
				range: true,
				tooltip: 'always',
				tooltip_split: true,
				formatter: function(){
					return $maxValue;
				},
				value: [
					parseFloat($valueOptions.attr('data-' + className + '-min-value')), 
					parseFloat($valueOptions.attr('data-' + className + '-max-value'))
				]
			});	

		// Obali slider a prida na zacatek a konec vybrane hodnoty
		$('.check-param[data-filter-name="filter' + filterName + '"] .bootstrap-slider').wrap('<div class="' + className + '-filter-wrap elements-wrapper filter-items"></div>');
		$('<div class="' + className + '-filter-min">' + $minValue + '</div><span class="' + className + '-filter-currency">' + currency + '</span>').insertBefore('.' + className + '-filter-wrap');
		$('<div class="' + className + '-filter-max">' + $maxValue + '</div><span class="' + className + '-filter-currency">' + currency + '</span>').insertAfter('.' + className + '-filter-wrap');
		$('.check-param[data-filter-name="filter' + filterName + '"] .param-filter__title').insertBefore('.' + className + '-filter-min').addClass('' + className + '-filter-title');

		// Na stop slideru
		valueSliderPlugin.on('slideStop', function(){
			//document.querySelector('#ajax-spinner').style = 'display: block';

			var $borderMinValue = parseFloat($('.' + className + '-slider-options').data('border-' + className + '-min-value'));
			var $borderMaxValue = parseFloat($('.' + className + '-slider-options').data('border-' + className + '-max-value'));

			var $currentMinValue = parseFloat($('.' + className + '-slider-options').siblings('.' + className + '-filter-wrap').find('.min-slider-handle').attr('aria-valuenow'));
			var $currentMaxValue = parseFloat($('.' + className + '-slider-options').siblings('.' + className + '-filter-wrap').find('.max-slider-handle').attr('aria-valuenow'));

			//var $filterValueCheck = $('.check-param[data-filter-name="filter' + filterName + '"] .acti-checkbox.check-' + filterName + '');
			var valuesToCheck = document.querySelectorAll('.check-param[data-filter-name="filter' + filterName + '"]'),
				_filter = Array.prototype.filter;


			var $checksToBeChecked = [],
				_filter = Array.prototype.filter,
				countChecks = 0,
				currentValues = [$currentMinValue, $currentMaxValue],
				defaultValues = [$borderMinValue, $borderMaxValue];

				//console.log(getURLParams("limiter[" + filterName + "][min]"));

				//limitQueryRange('filter' + filterName, currentValues, defaultValues);
/*
				if ($borderMinValue === $currentMinValue && $borderMaxValue === $currentMaxValue) {
					InteractiveFilterGlobalInstance.submit($('[data-filter-id="max"]'));
				}
	*/

			var checks = document.querySelectorAll('.check-' + filterName),
				toCheck = [];

			_filter.call(checks, function(_this) {
				var num = Number(_this.querySelector('.acti-checkbox-title').innerHTML.replace(',','.').replace(' cm', ''));
				if (num >= currentValues[0] && num <= currentValues[1]) {					
					toCheck.push(num);
				}
			});
			
			interactiveFilterSubmitAllowed = false;

			_filter.call(checks, function(_this) {
				if (!hasClass(_this, 'zero') && hasClass(_this, 'checked')) {
					_this.click();
				}
			});

			_filter.call(checks, function(_this) {
				var num = Number(_this.querySelector('.acti-checkbox-title').innerHTML.replace(',','.').replace(' cm', ''));
				if (toCheck.indexOf(num) > -1) {
					if (!hasClass(_this, 'zero')) {
						if ($borderMinValue !== $currentMinValue || $borderMaxValue !== $currentMaxValue) {
							_this.click();
						}
					}
				}
			});

			interactiveFilterSubmitAllowed = true;

			InteractiveFilterGlobalInstance.submit($('[data-filter-id="max"]'));


		});


		valueSliderPlugin.on('slide', function(){
			var $currentMinValue = parseFloat($('.' + className + '-slider-options').siblings('.' + className + '-filter-wrap').find('.min-slider-handle').attr('aria-valuenow'));
			var $currentMaxValue = parseFloat($('.' + className + '-slider-options').siblings('.' + className + '-filter-wrap').find('.max-slider-handle').attr('aria-valuenow'));

			$('.' + className + '-filter-min').html($currentMinValue);
			$('.' + className + '-filter-max').html($currentMaxValue);

		});

	}
}



$(document).ready(function(){
	var $interactiveFilter = $('#interactive-filter');
	var $interactiveFilterLists = $('#interactive-filter-lists');
	
	
	if($interactiveFilter.length === 0) { // pokracuji ve scriptu jen kdyz je na strance filtrace (pozadovany element)
		return;
	}

	var myFilters = {};
	
	myFilters.price = createPriceFilter('class="param-filter filter out-price-range"', 2);
	myFilters.producers = createCheckProducersFilter('producers', 'class="producers-filter"', 'Značka', {}, 3);
	//createCheckVariantFilter(variantFilterName, containerAttrs, title, args, position)
	myFilters.variant = createCheckVariantFilter('variant', 'class="param-filter variant-filter"', 'Velikost:', {}, 2);
	myFilters.stockAvailability = createStockFilter('stockAvailability', 'stockAvailability', 'Dostupnost', {}, 1);
	myFilters.list = createListsFilter('list', 'sale', 'id="list-sale"', {}, 1);
	
	var paramFilterSettings = JSON.parse($interactiveFilter.attr('data-variant-param-filter-settings'));
		
	var filterImplementationName, filterTitle, obj;	
	for(var key in paramFilterSettings){
		filterImplementationName = paramFilterSettings[key].implementation_name;
		filterTitle = paramFilterSettings[key].name;

		obj = {};
		obj[filterImplementationName] = createCheckParamFilter(filterImplementationName, '', filterTitle, filterImplementationName, key + 2);

		myFilters = $.extend(true, myFilters, obj);
	}	
	//console.log(obj);


	$interactiveFilter.interactiveFilter({
    	initialLimiters: $interactiveFilter.attr('data-limiters-url'),
		filters: myFilters,
		reloadOnRequestFilters: false,
		actualCategory: $interactiveFilter.attr('data-actual-category'),
    	priceCategory: $interactiveFilter.attr('data-price-category'),
    	stockAvailabilities: [0, 3, 7],
		afterRender: function(interactiveFilter){

			var filterContent = document.querySelectorAll('.param-filter__content'),
				_filter = Array.prototype.filter;
/*
				_filter.call(filterContent, function(_this) {
					var itemsToSort = [];
					_filter.call(_this.querySelectorAll('.acti-checkbox'), function(__this) {
						itemsToSort.push(__this);
					});					
					
					window.onload = function() {
						itemsToSort.sort(function(a,b) {
							if (isNaN(parseFloat(itemsToSort[0].querySelector('.acti-checkbox-title').innerHTML.replace(',', '.')))) {
								return a.querySelector('.acti-checkbox-title').innerHTML.localeCompare(b.querySelector('.acti-checkbox-title').innerHTML);
							} else {
								return a.querySelector('.acti-checkbox-title').innerHTML - b.querySelector('.acti-checkbox-title').innerHTML;
							}
						});		
						

						_this.innerHTML = '';

						_filter.call(itemsToSort, function(_value) {
							_this.appendChild(_value);
						});
					}

				});
		*/		
/*
				_filter.call(filterContent, function(_this) {
					var itemsToSort = [];
					_filter.call(_this.querySelectorAll('.acti-checkbox'), function(__this) {
						itemsToSort.push(__this);
					});					
					
					window.onload = function() {
						/*
						itemsToSort.sort(function(a,b) {
							if (isNaN(parseFloat(itemsToSort[0].querySelector('.acti-checkbox-title').innerHTML.replace(',', '.')))) {
								return a.querySelector('.acti-checkbox-title').innerHTML.localeCompare(b.querySelector('.acti-checkbox-title').innerHTML);
							} else {
								return a.querySelector('.acti-checkbox-title').innerHTML - b.querySelector('.acti-checkbox-title').innerHTML;
							}
						});					

						_this.innerHTML = '';

						itemsToSort.sort(function(a,b) {
							return a.querySelector('.acti-checkbox-title').innerHTML.localeCompare(b.querySelector('.acti-checkbox-title').innerHTML);						
						});

						_filter.call(itemsToSort, function(_value) {
							_this.appendChild(_value);
						});
					}

				});
				*/

				//return a.innerHTML.localeCompare(b.innerHTML);
				//.sort(function(a,b) {});

			
			$.each($('.param-filter__content'), function() {
				
				var $self = $(this);
				if ($self.parents('.stock-availability-filter').length < 1 &&
					$self.parents('.filterurceni').length < 1) {
				$self.find('.acti-checkbox').sort(function(a,b) {
					return $(a).text().localeCompare($(b).text());
				   }).appendTo($self); 
				}

			});			
			


			/*
			$('.param-filter__content .acti-checkbox').sort(function(a,b) {
				return $(a).text().localeCompare($(b).text());
		   }).appendTo('.param-filter__content'); 
		   */

			// Zakazani pouzivani labelu
			var actiCheckboxes = document.querySelectorAll('.acti-checkbox');
			_filter.call(actiCheckboxes, function(_this) {
				_this.querySelector('label').addEventListener('click', function(e) {
					e.preventDefault();
				});
			});

			/*
			$('.acti-checkbox label').on('click', function(e) {
				e.preventDefault();
			});
			*/		
			
			// ============= Zobrazi ceny vedle priceslideru ============================
			$('<div class="price-filter-min"></div>').insertBefore('.price-filter-wrap');
			$('<div class="price-filter-max"></div>').insertAfter('.price-filter-wrap');

			var $priceFilterMin = $('.price-filter-min');
			var $priceFilterMax = $('.price-filter-max');
			
			$priceFilter = $('[data-filter-name="price"]');
			$priceFilterSignMin = $priceFilter.find('.price-sign[data-filter-id="min"]');
			$priceFilterSignMax = $priceFilter.find('.price-sign[data-filter-id="max"]');
			
			var $priceFilterMinPrice = $priceFilterSignMin.attr('data-price-value');
			var $priceFilterMaxPrice = $priceFilterSignMax.attr('data-price-value');
			
			$priceFilterMin.html(addSpaces(localePrice($priceFilterMinPrice)));
			$priceFilterMax.html(addSpaces(localePrice($priceFilterMaxPrice)));
	
			// =========================================================================
			
			
			// Rozmístění filtrů
			// ==================================================
			
			// Přesune filty štítků do požadované části filtrace
			$('#list-sale').appendTo('.acti-checkboxes-lists');
			
			
			// Odmaže nepotřebné(skrýté) filtry štítků z DOMu
			/*
			$.each($('.filter-list'), function() {
				var $listFilter = $(this);
				if (!$listFilter.is(':visible')) {
					$listFilter.remove();
				}
			});
			*/
			// NEODZKOUSENO
			_filter.call(document.querySelectorAll('.filter-list'), function(_this) {
				if (!isVisible(_this)) {
					_this.parentNode.removeChild(_this);
				}
			});
			
			// ==================================================			
			
			// Zobrazení filtrů podle kategorie - podle JSONu v šabloně - parametr i přiřazený štítěk kategorii musí mít stejný implementační název
			if (typeof categoryFilters !== 'undefined') {	
				/*					
				$.each($('.check-param'), function() {
					var $filter = $(this);
					var $filterImplName = $filter.data('filter-name');
					if ($filterImplName in categoryFilters) {
						$filter.addClass('category-filter');
					}					
				});
				*/
				_filter.call(document.querySelectorAll('.check-param'), function(_this) {
					var filterImplName = _this.getAttribute('data-filter-name');
					if (filterImplName in categoryFilters) {
						addClass(_this, 'category-filter');
					}	
				});
			} else {
				//$('.check-param').addClass('category-filter');
				_filter.call(document.querySelectorAll('.check-param'), function(_this) {
					addClass(_this, 'category-filter');
				});
			}
			
			
			// Urci, ktere filtry maji byt na onload viditelne - podle prirazeneho stitku s postfixem -main
			if (typeof mainFilters !== 'undefined') {
				_filter.call(document.querySelectorAll('.check-param'), function(_this) {
					var filterImplName = _this.getAttribute('data-filter-name'),
						filterImplNameMain = filterImplName + '-main';									
					if (filterImplNameMain in mainFilters) {
						addClass(_this, 'main-filter');
					}
				});
				/*
				$.each($('.check-param'), function() {
					var $filter = $(this);
					var $filterImplNameMain = $filter.data('filter-name') + '-main';									
					if ($filterImplNameMain in mainFilters) {
						$filter.addClass('main-filter');
					}					
				});
				*/
			} /*else {
				$('.check-param').addClass('main-filter');
			}*/
		
			
			
			// ==================================================
			
			
			// INIT SLIDERU - RANGE
			newFilterSlider('delka', $numbersDelka, 'length', 'cm');
			newFilterSlider('radius', $numbersRadius, 'radius', 'm');
			newFilterSlider('sirkalyze', $numbersSirkaLyze, 'sirkalyze', 'mm');

			/*
			newFilterSlider('sirkaboty', $numbersSirkaBoty, 'sirkaboty', 'mm');
			newFilterSlider('vyskapostavy', $numbersVyskaPostavy, 'vyskapostavy', 'cm');
			newFilterSlider('teplotniurcenivosku', $numbersTeplotniUrceniVosku, 'teplotniurcenivosku', '°C');
			newFilterSlider('velikost', $numbersVelikost, 'velikost', 'mm');
			newFilterSlider('objem', $numbersObjem, 'objem', 'm3');
			newFilterSlider('vodnisloupec', $numbersVodniSloupec, 'vodnisloupec', 'mm');
			newFilterSlider('propustnost', $numbersPropustnost, 'propustnost', 'mm');
			newFilterSlider('drop', $numbersDrop, 'drop', 'mm');
			newFilterSlider('hmotnost', $numbersHmotnost, 'hmotnost', 'kg');
*/

		
			// Zarovna skladovost ve filtrech
			$('.stock-availability-filter').appendTo('.check-stock-filter');				
			
			
			///////// MOBILNI FILTRACE //////////
			var resizeCount = 0;
			function mobileFiltrationChanges() {
				if ($(window).width() <= 767) {
					$('<i class="icon icon--more"></i>').appendTo('#mobileFiltrationPopUp .param-filter__title');
					$('<div class="mobile-range-min">od </div><div class="mobile-range-max"> do </div>').insertBefore('#mobileFiltrationPopUp .bootstrap-slider');
					
					$('.length-filter-min').appendTo('.length-filter-wrap .mobile-range-min');
					$('.length-filter-max').appendTo('.length-filter-wrap .mobile-range-max');
					
					$('.price-filter-min').appendTo('.price-filter-wrap .mobile-range-min');
					$('.price-filter-max').appendTo('.price-filter-wrap .mobile-range-max');
					
					$('.radius-filter-min').appendTo('.radius-filter-wrap .mobile-range-min');
					$('.radius-filter-max').appendTo('.radius-filter-wrap .mobile-range-max');
					
					$('.sirkalyze-filter-min').appendTo('.sirkalyze-filter-wrap .mobile-range-min');
					$('.sirkalyze-filter-max').appendTo('.sirkalyze-filter-wrap .mobile-range-max');

				} else {
					$('.param-filter__title .icon.icon--more').remove();				
				}
			}
			
			mobileFiltrationChanges();
			
			$(window).resize(function() {
				resizeCount += 1;
				if (resizeCount === 0) {
					mobileFiltrationChanges();
				}
			});
			
			
			
			$('#mobileFiltrationPopUp .param-filter__title').on('click', function() {
				if ($(window).width() <= 767) {
					$(this).toggleClass('active');
					$(this).find('.icon--more').toggleClass('active');
					$(this).next('.param-filter__content').slideToggle(400);
					
					if ($(this).hasClass('price-filter-title')) {
						$(this).siblings('.price-filter-wrap').slideToggle(400);
					}
					
					if ($(this).hasClass('length-filter-title')) {
						$(this).siblings('.length-filter-wrap').slideToggle(400);
					}
					
					if ($(this).hasClass('radius-filter-title')) {
						$(this).siblings('.radius-filter-wrap').slideToggle(400);
					}
					
					if ($(this).hasClass('sirkalyze-filter-title')) {
						$(this).siblings('.sirkalyze-filter-wrap').slideToggle(400);
					}
					
				}
			});
			
			// Otevirani a zavirani mobilniho filtru obsahujici slider - delka, radius, sirka lyze
			var $openStatus = 0;	
			$('#mobileFiltrationPopUp .check-param[data-filter-name="filterdelka"] .param-filter__title, #mobileFiltrationPopUp .check-param[data-filter-name="filterradius"] .param-filter__title, #mobileFiltrationPopUp .check-param[data-filter-name="filtersirkalyze"] .param-filter__title').on('click', function() {
				var $title = $(this);
				if ($openStatus === 0) {					
					$title.parents('.check-param').animate({'height': 120}, 400);
					$openStatus = 1;
				} else {				
					$title.parents('.check-param').animate({'height': 41}, 400);
					$openStatus = 0;
				}
			});
			
						
				
			if ($(window).width() >= 992) {
				// Vyska filtru, ktere maji byt zobrazene na onload
				// ==================================================
				var $priceSliderHeight = $('.param-filter[data-filter-name="price"]').outerHeight();
				var $mainFilterHeight = 0;
				
				/*
				$.each($('.main-filter'), function(){
					var $filter = $(this);
					$mainFilterHeight += $filter.outerHeight();
				});
				*/

				_filter.call(document.querySelectorAll('.main-filter'), function(_this) {
					$mainFilterHeight += _this.offsetHeight;
				});
				$mainFiltersHeight = parseInt($priceSliderHeight += $mainFilterHeight);
				//console.log($mainFiltersHeight);

	
				
			}

			// =====================================================
			
			/////////// PLATI PRO CELOU FILTRACI ////////////
			
			// Projevi zmeny v cele filtraci
			filtrationChanges();
			
			addClass(document.querySelector('#param-filters'), 'loaded');
			addClass(document.querySelector('#search-categories-container'), 'loaded');
	
			document.querySelector('#ajax-spinner').style = 'display: none';
			removeClass(document.querySelector('#param-filters'), 'param-filters__hidden');
			addClass(document.querySelector('.param-filters--button-align'), 'loaded');
			//filtersHeightAfterCount();
			
			setTimeout(function() {
				filtersOpenHeight();
			}, 300);
			

			var params = document.querySelectorAll('.check-param');

			_filter.call(params, function(_this) {
				var filterName = _this.getAttribute('data-filter-name');
				if (paramInfo.hasOwnProperty(filterName)) {	
					addClass(_this.querySelector('.param-filter__info'), 'active');
					_this.querySelector('.param-filter__info__text').innerHTML = paramInfo[filterName];
				}
			});

		},
		beforeSubmit: function() {
			var container = document.querySelector('#param-filters');
			container.style = 'opacity: .3;';
		},
		afterUpdateCounts: function() {
			var container = document.querySelector('#param-filters');
			container.style = 'opacity: 1;';
			filtersHeightAfterCount();
			activeFiltersList();				
		}

	});
});







