/* FUNKCIA POTREBNE K INITU */

// add to cart
/*
var addingToCart = 0; // nepridavat dva krat do kosiku na dvojklik

function addToCart(elem, evt) {
    evt.preventDefault();

    if (!addingToCart) {
        addingToCart = 1;

        $('#ajax-spinner').show();

        var cartUrl = '/' + $(elem).data('cart-url');
        var idProduct = $(elem).data('id-product');

        var idVariant = $(elem).attr('data-id-variant');
        var variantName = $(elem).attr('data-variant');
        var productCount = $(elem).attr('data-count');

        var productImg = $(elem).data('img');
        var productName = $(elem).data('name');
        var productAvailability = $(elem).data('availability');
        var productPrice = $(elem).attr('data-price');

        var invalidationUrl = $('#bonusSnippet').length ? '?do=invalidateSnippets&snippetNames[]=cartbox&snippetNames[]=bonusSnippet' : '?do=invalidateSnippet&snippetName=cartbox';

        $.ajax({
            url: cartUrl + '?addingProductId=' + idProduct + '&addingProductCount=' + productCount + '&variantId=' + idVariant,
            dataType: 'html',
            success: function(data, textStatus) {
                $.nette.ajax({
                    url: invalidationUrl,
                    start: function () { },
                    complete: function () {
                        $('#ajax-spinner').hide();
                        $.magnificPopup.open({
                            items: {
                                src: '#addToCartPopup'
                            },
                            type: 'inline',
                            fixedContentPos: true,
                            callbacks: {
                                open: function() {
                                    // narvat data do elementov
                                    var productImgElem = document.createElement('img');
                                    $(productImgElem).attr('src', productImg);
                                    $('#cartPopupImg').append($(productImgElem));

                                    $('#cartPopupName').html(productName);
                                    $('#cartPopupVariantName').html(variantName);
                                    $('#cartPopupCount').html(productCount);

                                    if (productAvailability == 1) {
                                        $('#cartPopupAvailable').removeClass('hidden');
                                    } else {
                                        $('#cartPopupNotAvailable').removeClass('hidden');
                                        $('#cartPopupAvailability').html(productAvailability);
                                    }

                                    $('#cartPopupPrice').html(productPrice);

                                    // close
                                    $('#cartPopupBack').on('click', function() {
                                        $.magnificPopup.close();
                                    });
                                },
                                close: function() {
                                    // clean up
                                    $('#cartPopupImg, #cartPopupName, #cartPopupCount, #cartPopupAvailability, #cartPopupPrice').html('');
                                    $('#cartPopupAvailable').addClass('hidden');
                                    $('#cartPopupNotAvailable').addClass('hidden');

                                    addingToCart = 0; // zapnut moznost pridat produkt
                                }
                            }
                        });
                    }
                });
            }
        });
    }
}
*/
