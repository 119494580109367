/* CUSTOM KOD PRE SHOP */

// custom funkcie
var customContentFunc = {
    default: function() {
    },
    detail: function() {
        $('#tabs-1 iframe').wrap( "<div class='flex-video'></div>" );
    },
    search: function() {
    },
    cartDefault: function() {
    }
};

$(function () { //document ready

});

function limitToggle(limiter) {
    var ur = decodeURIComponent(window.location) + "";

    if (ur.indexOf('?') != -1 ){
        if (ur.indexOf(limiter) != -1 ){
            if (ur.indexOf('&'+limiter) != -1 ){
                s = ur.replace('&'+limiter,'');
            }else{
                s = ur.replace('?'+limiter,'');
            }

            if (s.indexOf('?') === -1 ){
                s = s.replace('&','?');
            }

        } else {
            s = $(window.location).attr('href')+'&'+limiter;
        }
    } else {
        s = $(window.location).attr('href')+'?'+limiter;
    }

    $.nette.ajax({
        url: s
    },this).complete(function () {
        window.history.pushState('', '', s);
    });
}
