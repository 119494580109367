/* OBECNE FUNKCE */

// browser detect
var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },
    classDoc: function () {
        var data = this.dataBrowser;

        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                var newClassName = " " + data[i].class;
                document.body.className += newClassName;

                return data[i].class;
            }
        }
    },

    dataBrowser: [
        {string: navigator.userAgent, subString: "Edge", identity: "MS Edge", class: "ie ie-edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "Explorer", class: "ie"},
        {string: navigator.userAgent, subString: "Trident", identity: "Explorer", class: "ie"},
        {string: navigator.userAgent, subString: "Firefox", identity: "Firefox", class: "ff"},
        {string: navigator.userAgent, subString: "Opera", identity: "Opera", class: "opera"},
        {string: navigator.userAgent, subString: "OPR", identity: "Opera", class: "opera"},

        {string: navigator.userAgent, subString: "Chrome", identity: "Chrome", class: "chrome"},
        {string: navigator.userAgent, subString: "Safari", identity: "Safari", class: "safari"}
    ]
};

BrowserDetect.classDoc();


// loading json
function loadJSON(callback, fname) {
    var xobj = new XMLHttpRequest();
    xobj.overrideMimeType("application/json");
    xobj.open('GET', fname, true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
        if (xobj.readyState == 4 && xobj.status == "200") {
            callback(xobj.responseText);
        }
    };
    xobj.send(null);
}

// camelize BEM classnames e.g. cart--default - see run.js
function camelizeClassName(className) {
    return className.toLowerCase().replace(/--(.)/g, function(match, group) {
        return group.toUpperCase();
    });
}

// parse url params
function getURLParams(){
    var search = location.search.substring(1);

    if(search === ''){
      return {};
    }

    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
}

// czech price
function localePrice(val, currency) {
    if (typeof currency === 'undefined') {
        currency = $('body').data('currency');
    }

    var locale = '';
    switch ($('body').data('locale')) {
        case 'cze':
            locale = 'cs';
            break;
        case 'svk':
            locale = 'sk';
            break;
        default:
            locale = 'cs';
            break;
    }


    return val.toLocaleString(locale) + ' ' + currency;
}

// appending slides dynamically to slick
function appendSlide(content, class_item, class_img) {
    slide = typeof class_item !== 'undefined' ? $('<div>', {class: class_item}) : $('<div>');

    img_wrap = typeof class_img !== 'undefined' ? $('<div>', {class: class_img + ' img'}) : $('<div>', {class: 'img'});
    img_wrap.append(content);

    return slide.append(img_wrap);
}

// © DAAR
// hasClass, addClass, removeClass, toggleClass;
// ===================================================================================
var hasClass, addClass, removeClass, toggleClass;

if ('classList' in document.documentElement) {
    hasClass = function (el, className) { return el.classList.contains(className); };
    addClass = function (el, className) { el.classList.add(className); };
    removeClass = function (el, className) { el.classList.remove(className); };
    toggleClass = function (el, className) { el.classList.toggle(className); };
} else {
    hasClass = function (el, className) {
        return new RegExp('\\b'+ className+'\\b').test(el.className);
    };
    addClass = function (el, className) {
        if (!hasClass(el, className)) { el.className += ' ' + className; }
    };
    removeClass = function (el, className) {
        el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
    };

    toggleClass = function (el, className) {
        var classes = el.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        el.className = classes.join(' ');
    };
}
// ===================================================================================
// Is visible?
function isVisible(element) {
    if (element.offsetWidth > 0 || element.offsetHeight > 0) {
        return true;
    } else {
        return false;
    }
}